import { IServiceFragment } from "@/graphql/fragments/__generated__/service.types";
import { useDeleteServiceMutation } from "@/graphql/mutations/__generated__/deleteService.types";
import { useUpsertServiceMutation } from "@/graphql/mutations/__generated__/upsertService.types";
import { GetServicesByObitSlugDocument } from "@/graphql/queries/__generated__/getServicesByObitSlug.types";
import { formatServices } from "@/helpers/services";
import { Drawer, useSnackbar } from "@gannettdigital/community-hub-components";
import React from "react";
import { IExtendedServiceFragment } from "../ServicesForm/Services.form";
import ServicesForm from "../ServicesForm/ServicesForm";

interface IProps {
  open: boolean;
  defaultValues: IServiceFragment[];
  onClose: () => void;
  obitId: string;
}

const ServicesDrawer: React.FC<IProps> = ({
  open,
  defaultValues,
  onClose,
  obitId
}) => {
  const { showSnackbar } = useSnackbar();

  const [upsertService] = useUpsertServiceMutation({
    refetchQueries: [
      "GetServicesByObitSlug",
      {
        query: GetServicesByObitSlugDocument,
        variables: {
          id: obitId
        }
      }
    ],
    onCompleted: () => {
      showSnackbar({
        title: "Thank you!",
        message: "The Services were updated!"
      });
    },
    onError: () =>
      showSnackbar({
        title: "An error has occurred!",
        message: "You might not have permissions for this operation!",
        autoHideDuration: 5000
      })
  });

  const [deleteService] = useDeleteServiceMutation({
    refetchQueries: [
      "GetServicesByObitSlug",
      {
        query: GetServicesByObitSlugDocument,
        variables: {
          id: obitId
        }
      }
    ],
    onCompleted: () => {
      showSnackbar({
        title: "Thank you!",
        message: "The Service was deleted!"
      });
    },
    onError: () =>
      showSnackbar({
        title: "An error has occurred!",
        message: "You might not have permissions for this operation!",
        autoHideDuration: 5000
      })
  });

  const onSubmit = (values: IExtendedServiceFragment[]) => {
    const variables = formatServices(values, obitId);
    console.log({ variables })

    upsertService({
      variables
    });

    onClose();
  };

  return (
    <Drawer open={open}>
      <ServicesForm
        onDelete={id => deleteService({ variables: { id } })}
        onSubmit={onSubmit}
        onClose={onClose}
        defaultValues={defaultValues}
      />
    </Drawer>
  );
};

export default ServicesDrawer;
