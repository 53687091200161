// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type IObitMemoryCheckFragment = (
  { __typename?: 'memories' }
  & Pick<Types.IMemories, 'id'>
  & { obit?: Types.Maybe<(
    { __typename?: 'obits' }
    & Pick<Types.IObits, 'slug'>
  )> }
);

export const ObitMemoryCheckFragmentDoc = gql`
    fragment ObitMemoryCheck on memories {
  id
  obit {
    slug
  }
}
    `;