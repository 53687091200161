import styled from "@/components/contexts/ThemeProvider/styled";

export const Container = styled("div")(({ theme }) => ({
  "&>:not(style)": {
    marginTop: theme.spacing(2)
  },

  "&>div:first-of-type": {
    marginTop: 0
  },

  [theme.breakpoints.up("sm")]: {
    "&>:not(style)": {
      marginTop: theme.spacing(2.5)
    }
  },

  [theme.breakpoints.up("md")]: {
    "&>:not(style)": {
      marginTop: theme.spacing(4)
    }
  }
}));
