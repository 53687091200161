import { ExpandedViewIcon, ListViewIcon } from "@/components/atoms";
import { RESULT_VIEW } from "@/components/contexts";
import {
  FiltersContext,
  getQueryValue
} from "@/components/contexts/FiltersProvider/FiltersProvider";
import {
  IndexPagePublicationsDropdown as PublicationsDropdown,
  IndexPageShowMeDropdown as ShowMeDropdown,
  Stepper
} from "@/components/molecules";
import { FiltersDrawer, SearchBar } from "@/components/organisms";
import { isUrlShowMeEqualToDefault } from "@/helpers/showMe";
import { useWebFilters } from "@/hooks/useAppState";
import { INDEX_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { useRouter } from "next/router";
import React, { useContext, useMemo } from "react";
import {
  Container,
  DesktopSearchContainer,
  DesktopSearchWrapper,
  DisplayOptionsContainer,
  DisplayViewIconsWrapper,
  MobileDrawerWrapper,
  MobileSearchContainer,
  OptionText,
  OptionWrapper,
  StepperContainer
} from "./IndexPageOptions.styled";

interface IProps {
  characters?: string[];
  openMobileAdvancedSearch?: boolean;
}

const alphabet = Array.from(Array(26))
  .map((_, i) => i + 65)
  .map((x) => String.fromCharCode(x));

const Options: React.FC<IProps> = ({
  characters,
  openMobileAdvancedSearch,
}) => {
  const webFilters = useWebFilters();
  const { applyFiltersToRoute, currentView, changeView } =
    useContext(FiltersContext);
  const { query } = useRouter();
  const showMe = getQueryValue(query, "showMe");
  const { trackEvent } = useAnalytics();

  const Search = useMemo(() => {
    return (
      <>
        {/* Mobile */}
        {openMobileAdvancedSearch && (
          <MobileSearchContainer>
            <SearchBar
              onSubmit={(term: string) => applyFiltersToRoute({ search: term })}
            >
              {webFilters && webFilters.length > 1 && (
                <PublicationsDropdown onFiltersChange={applyFiltersToRoute} />
              )}
            </SearchBar>

            <MobileDrawerWrapper>
              <FiltersDrawer />
            </MobileDrawerWrapper>
          </MobileSearchContainer>
        )}
        {/* Desktop */}
        <DesktopSearchContainer>
          <DesktopSearchWrapper>
            <SearchBar
              onSubmit={(term: string) => applyFiltersToRoute({ search: term })}
            >
              {webFilters && webFilters.length > 1 ? (
                <PublicationsDropdown onFiltersChange={applyFiltersToRoute} />
              ) : null}
            </SearchBar>
            <div>
              <FiltersDrawer />
            </div>
          </DesktopSearchWrapper>
        </DesktopSearchContainer>
      </>
    );
  }, [applyFiltersToRoute, openMobileAdvancedSearch, webFilters]);

  const AlphabetStepper = () =>
    useMemo(() => {
      return (
        <>
          {showMe && !isUrlShowMeEqualToDefault(showMe) && (
            <>
              <StepperContainer>
                <Stepper
                  steps={alphabet}
                  enabledSteps={characters ? characters : []}
                  onClickCallback={(character) => {
                    if (!character) return;

                    applyFiltersToRoute({
                      showMe,
                      character,
                    });

                    trackEvent({
                      action: `show me|${encodeURIComponent(
                        String(query.showMe)
                      )}|${character}`,
                      pageType: INDEX_PAGE_TYPE,
                    });
                  }}
                />
              </StepperContainer>
            </>
          )}
        </>
      );
    }, []);

  return (
    <Container>
      {Search}

      <DisplayOptionsContainer>
        <OptionWrapper>
          <OptionText variant="body1">Show me:</OptionText>
          <ShowMeDropdown
            onChange={(e) => {
              const value = e.target.value.toString();

              applyFiltersToRoute({ showMe: value });

              trackEvent({
                action: `show me|${encodeURIComponent(
                  e.target.value.toString()
                )}`,
                pageType: INDEX_PAGE_TYPE,
              });
            }}
          />
        </OptionWrapper>
        <OptionWrapper>
          <OptionText variant="body1">Display:</OptionText>
          <DisplayViewIconsWrapper>
            <ExpandedViewIcon
              isClicked={currentView === RESULT_VIEW.expanded}
              onClick={() => changeView(RESULT_VIEW.expanded)}
            />
            <ListViewIcon
              isClicked={currentView === RESULT_VIEW.list}
              onClick={() => changeView(RESULT_VIEW.list)}
            />
          </DisplayViewIconsWrapper>
        </OptionWrapper>
      </DisplayOptionsContainer>

      <AlphabetStepper />
    </Container>
  );
};

export default Options;
