import SearchResultItem from "@/components/common/SearchResultItem";
import { usePublicationId } from "@/hooks/useAppState";
import useDebounce from "@/hooks/useDebounce";
import CloseIcon from "@mui/icons-material/Close";
import { ClickAwayListener, Grid } from "@mui/material";
import get from "lodash/get";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Container,
  Divider,
  ResultsContainer,
  ResultsContainerParent,
  SearchIcon,
  TextField,
} from "./FilterInput.styled";

interface IVariables {
  variables: { term: string; id: string | number };
  skip: boolean;
}

interface IProps {
  minInputLength?: number;
  name: string;
  placeholder?: string;
  onChangeCallback?: (camelName: string, val: string) => void;
  query: (variables: IVariables) => any;
}

const FilterInput = ({
  minInputLength = 2,
  name,
  placeholder = "Search",
  query,
}: IProps) => {
  const publicationId = usePublicationId();
  const { control, setValue } = useFormContext();
  const [inputValue, setInputValue] = useState("");
  const [hasFocus, setHasFocus] = useState(false);
  const [skipQuery, setSkipQuery] = useState(false);

  const debouncedSearchQuery = useDebounce(inputValue, 300);

  const { data } = query({
    variables: {
      term: `%${debouncedSearchQuery}%`,
      id: publicationId,
    },
    skip:
      !debouncedSearchQuery ||
      debouncedSearchQuery.length < minInputLength ||
      skipQuery,
  });

  const displayObit = get(data, "display_obituary", []);

  // Trim whitespace and remove duplicates
  const dataSet: string[] = useMemo(() => {
    return Array.from(
      new Set(
        // eslint-disable-next-line camelcase
        displayObit.map((x: { [x: string]: string }) => x[name].trim())
      )
    );
  }, [displayObit, name]);

  const showSuggestion = useMemo(() => {
    return (
      hasFocus &&
      dataSet &&
      dataSet.length > 0 &&
      inputValue.length >= minInputLength &&
      // input === data[0] value
      !(inputValue.toLowerCase() === dataSet[0].toLowerCase()) &&
      // one match, data[0] value starts with input
      !(
        dataSet.length === 1 &&
        inputValue.toLowerCase().startsWith(dataSet[0].toLowerCase())
      )
    );
  }, [dataSet, inputValue, minInputLength, hasFocus]);

  return (
    <Container>
      <ClickAwayListener onClickAway={() => setHasFocus(false)}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs>
            <Controller
              control={control}
              defaultValue=""
              name={name}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={placeholder}
                  InputProps={{
                    endAdornment:
                      inputValue === "" ? (
                        <SearchIcon />
                      ) : (
                        <CloseIcon
                          onClick={() => {
                            field.onChange("");
                            setInputValue("");
                          }}
                        />
                      ),
                  }}
                  onChange={(e) => {
                    field.onChange(e.target.value);

                    setInputValue(e.target.value);

                    if (skipQuery) {
                      setSkipQuery(false);
                    }
                  }}
                  onClick={() => {
                    setHasFocus(true);
                  }}
                  type="input"
                  variant="outlined"
                />
              )}
            />

            {showSuggestion && (
              <ResultsContainerParent>
                <ResultsContainer>
                  {dataSet.map((term) => (
                    <Divider key={String(term)}>
                      <SearchResultItem
                        query={inputValue}
                        fullTerm={String(term)}
                        handleClick={() => {
                          setValue(name, term);
                          setInputValue(term);
                          setSkipQuery(true);
                        }}
                      />
                    </Divider>
                  ))}
                </ResultsContainer>
              </ResultsContainerParent>
            )}
          </Grid>
        </Grid>
      </ClickAwayListener>
    </Container>
  );
};

export default FilterInput;
