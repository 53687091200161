import styled from "@/components/contexts/ThemeProvider/styled";
import {
  unifySansBoldFontFamily,
  unifySansRgFontFamily
} from "@gannettdigital/community-hub-components";
import { Box, Tab as MuiTab, TabProps, Tabs as MuiTabs } from "@mui/material";
import React from "react";

export const Container = styled("div")(({ theme }) => ({
  padding: "20px 30px",

  [theme.breakpoints.up("sm")]: {
    padding: "30px"
  }
}));

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.all.blue5,
    height: "6px"
  }
}));


export const Tab = styled(
  (props: TabProps & { form?: string; type?: string }) => <MuiTab {...props} />
)(({ theme }) => ({
  backgroundColor: theme.palette.all.gray8,
  fontFamily: unifySansRgFontFamily,
  fontSize: "16px",
  textTransform: "none",

  "&.Mui-selected": {
    color: theme.palette.all.gray2,
    fontFamily: unifySansBoldFontFamily
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
