import { FtdCardValues } from "@/helpers/obituary";
import { FlowerIcon, Link } from "@gannettdigital/community-hub-components";
import {
  Address,
  Container,
  Content,
  Divider,
  Heading,
  Title,
} from "./SlugPageSendFlowersContent.styled";
import { useAnalytics } from "@/services/analyticsService";

interface IProps {
  data: FtdCardValues[];
}

const SendFlowersContent: React.FC<IProps> = ({ data }) => {
  const { trackEvent } = useAnalytics();

  return (
    <Container>
      {data.length > 1 && (
        <Heading variant="h4">Where would you like to send flowers?</Heading>
      )}
      {data.map(
        (
          { name, ftdUrl, address1, address2, city, state, country, zip },
          index
        ) => (
          <Link
            key={ftdUrl}
            href={ftdUrl}
            target="_blank"
            onClick={() => {
              trackEvent({
                category: "life-story",
                action: "popup-click",
                label: "send-flowers",
              });
            }}
          >
            <Content>
              <div>
                <Title variant="body2">{name}</Title>
                {address1 && <Address variant="body2">{address1}</Address>}
                {address2 && <Address variant="body2">{address2}</Address>}
                <Address variant="body2">
                  {city}, {state}, {country} {zip}
                </Address>
              </div>
              <FlowerIcon fontSize="large" htmlColor="white" />
            </Content>
            {index !== data.length - 1 && <Divider />}
          </Link>
        )
      )}
    </Container>
  );
};

export default SendFlowersContent;
