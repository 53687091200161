import { Typography, styled, unifySansBoldFontFamily } from "@gannettdigital/community-hub-components";
import MuiDivider from "@mui/material/Divider";

export const Divider = styled(MuiDivider)(({ theme }) => ({
  borderColor: theme.palette.all.gray4
}));

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  "& .MuiDivider-root": {
    margin: theme.spacing(2.5, 0),
  },
}));

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2.5)
}));

export const Content = styled('div')({
  display: "flex",
  justifyContent: "space-between"
});

export const Title = styled(Typography)({
  fontFamily: unifySansBoldFontFamily 
});

export const Address = styled(Typography)(({ theme }) => ({
  color: theme.palette.all.gray4
}));
