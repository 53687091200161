import styled from "@/components/contexts/ThemeProvider/styled";
import { LinearGradientContainer } from "@gannettdigital/community-hub-components";
import getConfig from "next/config";

const ImageContainer = styled(LinearGradientContainer)({
  display: "flex",
  justifyContent: "center",

  "& .full-height": {
    height: "100%"
  }
});

const { publicRuntimeConfig } = getConfig();

interface IProps {
  fullHeight?: boolean;
  assetName: string;
}

const ImageWithLinearGradient: React.FC<IProps> = ({
  fullHeight,
  assetName
}) => {
  return (
    <ImageContainer>
      <picture>
        <img
          alt=""
          className={fullHeight ? "full-height" : undefined}
          src={`${publicRuntimeConfig.assetsPrefix}/static/images/${assetName}`}
        />
      </picture>
    </ImageContainer>
  );
};

export default ImageWithLinearGradient;
