import { IService_Duration_Insert_Input, IService_Duration_Type_Enum, IService_Insert_Input } from "@/graphql/__generated__/graphql.types";
import { IExtendedServiceDurationFragment, IExtendedServiceFragment } from "./../components/organisms/ServicesForm/Services.form";

type FormattedServices = { durations: Array<IService_Duration_Insert_Input>, services: Array<IService_Insert_Input> }

/**
 * Used to build the `begin` or `end` value of a service_duration fragment
 * @param date Date object, used for the date part
 * @param time Date object, used for the time part
 * @param offset Timezone offset from UTC in seconds
 */
const buildDuration = (date: Date | null, time: Date | string | null, offset?: number | null) => {
  let actualDate = null;
  if (date) {
    actualDate = new Date(date);
  }
  
  const actualTime = time ? new Date(time) : null;

  let formattedDuration = null;

  if (actualDate && actualTime) {
    formattedDuration = new Date(actualDate);
    formattedDuration.setHours(actualTime.getHours());
    formattedDuration.setMinutes(actualTime.getMinutes());
    if (typeof offset === 'number') {
      formattedDuration.setHours(formattedDuration.getHours() - (offset / 60 / 60))
    }

    // subtract the local offset in hours
    formattedDuration.setHours(formattedDuration.getHours() - (formattedDuration.getTimezoneOffset() / 60)); 
  }

  return formattedDuration;
}

export const formatServiceDurations = (extendedDurationFragment: IExtendedServiceDurationFragment) => {
  // Remove __typename and the custom date field, format the begin and end values
  const {
    __typename,
    date,
    begin,
    begin_offset,
    end,
    end_offset,
    ...durationFragment
  } = extendedDurationFragment;

  const formattedBegin = buildDuration(date, begin, begin_offset);
  const formattedEnd = buildDuration(date, end, end_offset);

  return {
    ...durationFragment,
    begin: formattedBegin,
    end: formattedEnd,
    type: formattedBegin // If start time is picked
      ? IService_Duration_Type_Enum.DateTime
      : IService_Duration_Type_Enum.AllDay,
    begin_offset: begin_offset || null,
    end_offset: end_offset || null,
  }
}


export const formatServices = (
  services: IExtendedServiceFragment[],
  obitId: string
): FormattedServices => {
  const formattedServices = services.reduce(
    (acc, serviceFragment) => {
      // Format durations and split them: 1) to insert and 2) to update
      const durationsData = serviceFragment.service_durations.reduce(
        (acc, extendedDurationFragment) => {
          const updateDurationData = formatServiceDurations(extendedDurationFragment);

          // If id and service_id are truthy => keep them for updates, otherwise remove them for inserts
          const { id, service_id, ...insertDurationData } = updateDurationData;
          if (id && service_id) {
            return { insert: acc.insert, update: [...acc.update, updateDurationData] };
          }
          return {
            update: acc.update,
            insert: {
              data: [...acc.insert.data, insertDurationData],
            },
          };
        },
        {
          insert: { data: [] },
          update: [],
        } as { insert: { data: any[] }, update: any[] }
      );

      // Remove __typename, add obituary_id and durations to insert
      const { __typename, ...updateServiceData } = {
        ...serviceFragment,
        obituary_id: obitId,
        service_durations: durationsData.insert,
      };

      // If id is truthy => keep it for updates, otherwise remove it for inserts
      const { id: serviceId, ...insertServiceData } = updateServiceData;

      const upsertServiceData = serviceId
        ? updateServiceData
        : insertServiceData;

      return {
        durations: [...acc.durations, ...durationsData.update],
        services: [...acc.services, upsertServiceData],
      };
    },
    { durations: [], services: [] } as FormattedServices
  );
  return formattedServices;
};

