import styled from "@/components/contexts/ThemeProvider/styled";
import { Typography } from "@gannettdigital/community-hub-components";

export const FormHeading = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  marginBottom: 14,
  [theme.breakpoints.up("sm")]: {
    fontSize: 18,
    marginBottom: 18
  }
}));

export const FlexInputContainer = styled("div")(({ theme }) => ({
  "& > *, & > .MuiFormControl-root": {
    marginBottom: 25
  },

  [theme.breakpoints.up("sm")]: {
    display: "flex",
    gap: "30px"
  }
}));
