import SignInMenu from "@/components/molecules/SignInMenu/SignInMenu";
import { useWhitelist } from "@/hooks/useAppState";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { NavigationBar, Tab } from "@gannettdigital/community-hub-components";
import { ClickAwayListener } from "@mui/base";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { SigninContainer, TabNumberIndicator, TabWithNumberIndicatorContainer, Wrapper } from "./Tabs.styled";

interface IProps {
  slug: string;
  numberOfUpcommingServices?: number;
  hasServices?: boolean;
  defaultTab?: string;
  hasPictures?: boolean;
}

export enum OBIT_TABS {
  lifeInPictures = "Life In Pictures",
  lifeStory = "Life Story",
  memoryBoard = "Memory Board",
  services = "Services",
}

const Tabs: React.FC<IProps> = ({
  defaultTab = OBIT_TABS.lifeStory,
  slug,
  hasServices,
  numberOfUpcommingServices = 0,
  hasPictures = true,
}) => {
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const [open, setOpen] = useState(false);
  const qsp = router.asPath.split("?")[1]
    ? `?${router.asPath.split("?")[1]}`
    : "";
  const showPicturesTab = useWhitelist("picturesWhitelist") && hasPictures;

  const tabs = [
    { id: OBIT_TABS.lifeStory, label: OBIT_TABS.lifeStory, href: `/${qsp}` },
    ...(hasServices
      ? [
        {
          id: OBIT_TABS.services,
          label:
            <TabWithNumberIndicatorContainer>
              {OBIT_TABS.services}
              {numberOfUpcommingServices > 0 && <TabNumberIndicator>{numberOfUpcommingServices}</TabNumberIndicator>}
            </TabWithNumberIndicatorContainer>,
          href: `/services${qsp}`,
        },
      ]
      : []),
    {
      id: OBIT_TABS.memoryBoard,
      label: OBIT_TABS.memoryBoard,
      href: `/memoryboard${qsp}`,
    },
    ...(showPicturesTab
      ? [
        {
          id: OBIT_TABS.lifeInPictures,
          label: OBIT_TABS.lifeInPictures,
          href: `/pictures${qsp}`,
        },
      ]
      : []),
  ];

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <SigninContainer>
          <Wrapper>
            <SignInMenu
              borderColor="white"
              anchor="right"
              open={open}
              onToggle={() => setOpen(!open)}
            />
          </Wrapper>
        </SigninContainer>
      </ClickAwayListener>
      <NavigationBar
        value={defaultTab}
        onChange={(ev, value) => {
          const tab = tabs.find((t) => t.id === value);
          if (!tab) {
            console.error(`tab ${value} not found`);
            return;
          }

          trackEvent({
            action: `action bar`,
            label: (ev.currentTarget as HTMLElement).innerHTML,
            pageType: DETAIL_PAGE_TYPE,
          });
          router.push(`/${slug}/${tab.href}`);
        }}
      >
        {tabs.map((tab) => (
          <Tab value={tab.id} key={tab.id} label={tab.label} />
        ))}
      </NavigationBar>
    </>
  );
};

export default Tabs;
