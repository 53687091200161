import { InputLabel, NoSsr, Select as MuiSelect } from "@mui/material";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, MenuItem } from "./Select.styled";
interface ISelectOptions {
  value: string | number;
  label: string;
}

interface IProps {
  label: string;
  name: string;
  options: ISelectOptions[];
  onChangeCallback: (value: string | number | unknown) => void;
}
const Select: React.FC<IProps> = ({
  label,
  name,
  options,
  onChangeCallback
}) => {
  const { control } = useFormContext();
  const id = label.toLowerCase().replace(/ /g, "_");
  const RangeOptions = useMemo(
    () =>
      options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <NoSsr>
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({field}) => (
          <FormControl variant="outlined" fullWidth>
            <InputLabel id={id}>{label}</InputLabel>
            <MuiSelect
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              labelId={id}
              label={label}
              onChange={e => {
                field.onChange(e.target.value);

                if (onChangeCallback) {
                  onChangeCallback(e.target.value);
                }
              }}
              value={field.value ?? ""}
            >
              {RangeOptions}
            </MuiSelect>
          </FormControl>
        )}
      />
    </NoSsr>
  );
};

export default Select;
