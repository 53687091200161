import styled from "@/components/contexts/ThemeProvider/styled";
import { unifySansRgFontFamily } from "@gannettdigital/community-hub-components";

export const IntroContainer = styled("div")(({ theme }) => ({
  background:
    "linear-gradient(to right, rgba(253, 236, 221, 0.51) -15.97%, rgba(253, 236, 221, 0) 113.39%)",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  gap: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row-reverse",
    padding: theme.spacing(7.5, 7.5, 0)
  },

  [theme.breakpoints.up("xl")]: {
    padding: theme.spacing(7.5, 20, 0)
  }
}));

export const Image = styled("img")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  maxWidth: '100%',

  [theme.breakpoints.up("sm")]: {
    marginBottom: 0,
    width: 250
  },

  [theme.breakpoints.up("md")]: {
    width: 375
  },

  [theme.breakpoints.up("lg")]: {
    width: 500
  }
}));

export const List = styled("ul")(({ theme }) => ({
  marginBlockEnd: 0,
  marginBlockStart: 0,
  paddingInlineStart: theme.spacing(2.5)
}));

export const ListItem = styled("li")(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: 14,
  fontFamily: unifySansRgFontFamily
}));
