import { IImageFragment } from "@/graphql/fragments/__generated__/image.types";
import { IUserCreatedObituaryFragment } from "@/graphql/fragments/__generated__/userCreatedObituary.types";
import { IExternal_Link_Type_Enum, IImage_Type_Enum } from "@/graphql/__generated__/graphql.types";
import * as yup from "yup";

export const DEFAULT_IMAGE_FRAGMENT: IImageFragment = {
  id: "",
  uri: "",
  type: IImage_Type_Enum.Obituary,
  params: "",
  caption: ""
};

export const FORM_DEFAULT_VALUES: IUserCreatedObituaryFragment = {
  id: "",
  slug: "",
  life_stories: [
    {
      id: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      suffix: "",
      birth: null,
      passing: null,
      story: ""
    }
  ],
  life_story_locations: [
    {
      id: "",
      city: "",
      state: "",
      country: ""
    }
  ],
  external_links: [
    {
      id: "",
      type: IExternal_Link_Type_Enum.Gofundme,
      uri: ""
    }
  ],
  images: [],
  obituary_tags: [],
  from_sales_orders: [
    {
      id: "",
      obituary_id: "",
      contact_firstname: "",
      contact_lastname: "",
      billing_address: "",
      billing_city: "",
      billing_state: "",
      billing_zip: "",
      billing_phone: "",
      billing_email: "",
      verification_provider: "",
      verification_contact: "",
      verification_phone: "",
      have_accepted_terms_and_service: false,
      print_start: null,
      print_end: null
    }
  ]
};

const messageRequired = "The field is required!";

export const validationSchema = yup.object().shape({
  life_stories: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required(messageRequired),
      middle_name: yup.string(),
      last_name: yup.string().required(messageRequired),
      suffix: yup.string(),
      birth: yup.date().required(messageRequired).nullable(),
      passing: yup
        .date()
        .required(messageRequired)
        .nullable()
        .min(yup.ref("birth"), "Passing date cannot be before birth date.")
    })
  ),
  life_story_locations: yup.array().of(
    yup.object().shape({
      city: yup.string().required(messageRequired),
      state: yup.string().required(messageRequired),
      country: yup.string().required(messageRequired)
    })
  ),
  external_links: yup.array().of(
    yup.object().shape({
      uri: yup.string()
    })
  )
});
