import styled from "@/components/contexts/ThemeProvider/styled";
import { Container } from "@mui/material";

export const SigninContainer = styled(Container)(() => ({
  position: "relative",
  justifyContent: "right",
  zIndex: 1099, // header is 1100
}));

export const Wrapper = styled("div")(({ theme }) => ({
  right: 32,
  marginTop: 12,
  float: "right",

  [theme.breakpoints.up("md")]: {
    position: "absolute",
    color: "white",
  },
}));

export const TabWithNumberIndicatorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
}));

export const TabNumberIndicator = styled("div")(({ theme }) => ({
  marginLeft: "5px",
  backgroundColor: theme.palette.all.white,
  color: theme.palette.all.blue5,
  borderRadius: "4px",
  padding: "3px 1px 4px 4px",
}));
