import { ImageRender } from "@/components/common/ImageRender";
import styled from "@/components/contexts/ThemeProvider/styled";
import { splitFullTerm } from "@/helpers/search";
import { formatImageURLToFastlyURL } from "@/helpers/urls";
import { serifFontFamily } from "@gannettdigital/community-hub-components";
import { Typography } from "@mui/material";
import get from "lodash/get";

const WIDTH = 26.5;
const HEIGHT = 35;

interface IProps {
  query: string;
  fullTerm: string;
  photoUrls?: string[];
  handleClick: () => void;
}

const SearchResultItem = ({
  query,
  fullTerm,
  photoUrls,
  handleClick,
}: IProps) => {
  const [fullTermP1, fullTermMatched, fullTermP2] = splitFullTerm(
    fullTerm,
    query
  );

  const imageSrc = get(photoUrls, "[0]");

  return (
    <Container
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      <FullTerm>
        <Typography
          display="inline"
          variant="body1"
          classes={{ root: "full-term-part" }}
        >
          {fullTermP1}
        </Typography>
        <Typography
          display="inline"
          variant="body1"
          classes={{ root: "full-term-matched" }}
        >
          {fullTermMatched}
        </Typography>
        <Typography
          display="inline"
          variant="body1"
          classes={{ root: "full-term-part" }}
        >
          {fullTermP2}
        </Typography>
      </FullTerm>

      {imageSrc && (
        <ImageRender
          src={formatImageURLToFastlyURL(imageSrc, WIDTH, HEIGHT)}
          alt={fullTerm}
          width={WIDTH}
          height={HEIGHT}
        />
      )}
    </Container>
  );
};

export default SearchResultItem;

const Container = styled("div")(({ theme }) => ({
  height: 44,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  transition: "all 0.5s ease",
  "& .MuiTypography-body1": {
    fontFamily: serifFontFamily,
    fontSize: 14,
  },
  "&:focus": {
    outline: 0,
    padding: theme.spacing(0, 1),
    fontStyle: "italic",
  },
}));

const FullTerm = styled("div")(({ theme }) => ({
  flexDirection: "row",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  "& .full-term-part": {
    fontSize: 14,
    whiteSpace: "nowrap",

    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },

  "& .full-term-matched": {
    fontSize: 14,
    whiteSpace: "nowrap",
    fontWeight: "bold",

    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
}));
