import Dropdown from "@/components/atoms/Dropdown/Dropdown";
import { IFilters } from "@/components/contexts/FiltersProvider/FiltersProvider.types";
import styled from "@/components/contexts/ThemeProvider/styled";
import { useWebFilters } from "@/hooks/useAppState";
import { unifySansRgFontFamily } from "@gannettdigital/community-hub-components";
import React, { useMemo } from "react";

interface IProps {
  onFiltersChange: (filters: IFilters) => void;
}

const Container = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3)
  },
  "& .select": {
    color: theme.palette.grey["600"],
    fontSize: 14,
    fontFamily: unifySansRgFontFamily
  }
}));

const Publication: React.FC<IProps> = ({ onFiltersChange }) => {
  const webFilters = useWebFilters();

  const publicationsOptions = useMemo(() => {
    return webFilters.map((option) => {
      const { name } = option;
      const values = option.web_config_filter_tags
        .map(pub => pub.tag.id)
        .join(",");
      return { value: values, label: name };
    })
  }, [webFilters]);

  return (
    <Container>
      <Dropdown
        options={publicationsOptions}
        additionalStyles={{
          formControlStyles: "publicationDropdown",
          selectStyles: "select"
        }}
        placeholder="Newspaper publication"
        onChangeCallback={(e, name) => {
          const ids = (e.target.value as string);
          onFiltersChange({
            ids,
            pubname: name
          });
        }}
      />
    </Container>
  );
};

export default Publication;
