import styled from "@/components/contexts/ThemeProvider/styled";
import {
    Button as CustomButton,
    Link,
    unifySansBoldFontFamily
} from "@gannettdigital/community-hub-components";
import { Theme } from "@mui/system";

export const Container = styled("div")(({ theme }: { theme: Theme }) => ({
  display: "flex",
  alignItems: "end",
  flexDirection: "row",
  borderBottom: `1px solid ${theme.palette.custom.gray1}`, //replace by theme color
  paddingBottom: theme.spacing(1.5),
  flexGrow: 1,
  justifyContent: "space-between",

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(3)
  },

  [theme.breakpoints.down("md")]: {
    flexDirection: "row-reverse"
  }
}));

export const SubmitObitLink = styled(Link)(({ theme }: { theme: Theme }) => ({
  color: `${theme.palette.custom.gray4} !important`, // root color takes precedence
  fontFamily: unifySansBoldFontFamily,
  fontSize: 14,
  lineHeight: "inherit",

  [theme.breakpoints.up("md")]: {
    marginTop: 0
  }
}));

export const MenuWrapper = styled("div")(() => ({
  position: "relative",
  zIndex: 1250
}));
export const SignInMenuWrapper = styled("div")(() => ({}));
export const Menu = styled("div")(({ theme }: { theme: Theme }) => ({
  position: "absolute",
  top: theme.spacing(4),
  backgroundColor: "#303030",
  right: 0,
  width: 200,
  borderTop: `3px solid #1D71A5`,

  [theme.breakpoints.down("md")]: {
    right: 0,
    left: -24,
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    zIndex: 10
  }
}));

export const MenuEntry = styled(Link)(({ theme }: { theme: Theme }) => ({
  color: "white",
  textDecoration: "none",
  borderBottom: "none !important",
  margin: theme.spacing(2),
  fontWeight: 700,
  display: "block",
  width: "fit-content",
  fontFamily: unifySansBoldFontFamily,

  "&:hover": {
    opacity: 0.5
  },

  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 36px)",
    paddingBottom: theme.spacing(2),
    borderColor: `${theme.palette.custom.gray4} !important`,
    borderWidth: `1px !important`
  }
}));

export const MenuLink = styled("div")({
  display: "flex"
});

export const Arrows = styled("div")(() => ({
  marginTop: -2
}));

export const Button = styled(CustomButton)(({ theme }: { theme: Theme }) => ({
  fontFamily: unifySansBoldFontFamily,
  marginBottom: 0,
  minWidth: "auto"
}));
