import { InDomainLink, inDomainLinkToHref } from "@/helpers/urls";
import { useDomain } from "@/hooks/useAppState";
import useAuth from "@/hooks/useAuth";
import { Menu, MenuItemArrow } from "@gannettdigital/community-hub-components";
import { useRouter } from "next/router";
import React from "react";

const LINKS: InDomainLink[] = [
  {
    label: "Manage Obituaries",
    path: "/manage",
    internal: true,
  },
  {
    label: "Manage Account",
    subdomain: "account",
    path: "/",
  },
  {
    label: "Newsletters",
    subdomain: "profile",
    path: "/newsletters/manage/",
  },
  {
    label: "Email Preferences",
    subdomain: "profile",
    path: "/newsletters/preferences/",
  },
  {
    label: "Subscriber Guide",
    subdomain: "cm",
    path: "/subscriberguide",
  },
  { label: "Help Center", subdomain: "help", path: "/" },
];

interface SignInMenuProps {
  open: boolean;
  onToggle: () => void;
  borderColor?: string;
  anchor?: string;
}

export const SignInMenu: React.FC<SignInMenuProps> = ({
  open,
  onToggle,
  borderColor,
  anchor = "right",
}) => {
  const router = useRouter();
  const domain = useDomain();
  const { authState, authUrls } = useAuth();

  const doneFetching = !!authState?.hasMadeInitialGupCall;
  const loggedOffLabel = doneFetching ? "Sign in" : "";
  const loggedInLabel = doneFetching ? `Hi, ${authState?.firstName}` : "";

  return (
    <Menu
      open={open}
      isLoggedIn={authState?.isAuthenticatedInCurrentContext || false}
      onToggle={onToggle}
      loggedInLabel={loggedInLabel}
      loggedOffLabel={loggedOffLabel}
      onSignIn={() => router.push(authUrls.authenticateUrl)}
      anchor={anchor}
      borderColor={borderColor}
    >
      {LINKS.map((inDomainLink) => {
        const href = domain ? inDomainLinkToHref(domain, inDomainLink) : "";

        if (inDomainLink.internal) {
          // link is internal to obits
          return (
            <MenuItemArrow
              key={href}
              onClick={() => router.push(inDomainLink.path)}
            >
              {inDomainLink.label}
            </MenuItemArrow>
          );
        }

        return (
          <MenuItemArrow key={href} href={href}>
            {inDomainLink.label}
          </MenuItemArrow>
        );
      })}
      <MenuItemArrow href={authUrls.logoutUrl}>Sign out</MenuItemArrow>
    </Menu>
  );
};

export default SignInMenu;
