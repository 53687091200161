import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { unifySansBoldFontFamily } from "@gannettdigital/community-hub-components";
import React from "react";

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
  compact?: boolean;
  title: string;
}

const Title = styled(
  ({ title, ...props }: TitleProps) => <div {...props}>{title}</div>,
  {
    shouldForwardProp: prop => prop !== "compact"
  }
)(({ theme, compact }) => ({
  fontFamily: unifySansBoldFontFamily,
  alignItems: "center",
  display: "flex",
  fontSize: "24px",
  margin: compact ? theme.spacing(0, 0, 2, 0) : theme.spacing(0, 0, 3, 0),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    margin: compact ? theme.spacing(0, 0, 2, 0) : theme.spacing(0, 0, 6, 0)
  },
  "&::after": {
    backgroundColor: Colors.gray4,
    content: "' '",
    flexGrow: 2,
    height: "4px",
    margin: theme.spacing(0.5, 0, 0, 1)
  }
}));

export default React.memo(Title);
