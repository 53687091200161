import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { unifySansRgFontFamily } from "@gannettdigital/community-hub-components";
import {
  Step as MuiStep,
  Stepper as MuiStepper,
  StepConnector
} from "@mui/material";

export const Stepper = styled(MuiStepper)({
  "& .paper": {
    padding: 0
  }
});

export const Dash = styled(StepConnector)({
  color: "#C4C4C4",
  width: "6px",
  top: "10px",
  left: "-3px",
  right: "auto",
  "& .line": {
    borderWidth: "2px",
    margin: "1px"
  }
});

export const Step = styled(MuiStep)({
  "& .horizontal-step": {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    paddingLeft: 4,
    paddingRight: 4
  }
});
interface StepIconProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
  isDisabled?: boolean;
}
export const StepIcon = styled((props: StepIconProps) => <div {...props} />, {
  shouldForwardProp: prop => prop !== "isActive" && prop !== "isDisabled"
})(({ isActive, isDisabled }) => ({
  textAlign: "center",
  minWidth: 22,
  fontWeight: "bold",
  fontFamily: unifySansRgFontFamily,
  fontSize: 16,
  cursor: "pointer",
  color: Colors.black,
  backgroundColor: Colors.white,
  textShadow: `1px 0 ${Colors.black}`,

  ...(isActive
    ? {
        color: Colors.white,
        backgroundColor: "#1D71A5",
        paddingRight: 1,
        textShadow: `1px 0 ${Colors.white}`
      }
    : {}),

  ...(isDisabled
    ? {
        cursor: "default",
        color: "#C4C4C4",
        textShadow: "1px 0 #C4C4C4",
        pointerEvents: "none"
      }
    : {})
}));

export const Container = styled("div")(({ theme }) => ({
  "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
  "scrollbar-width": "none", // Hide scrollbar for Firefox
  "&::-webkit-scrollbar": {
    // Hide scrollbar for Chrome, Safari and Opera
    display: "none"
  },
  padding: theme.spacing(1.5, 0),
  overflowX: "scroll",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1.5, 2)
  }
}));
