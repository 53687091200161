import { usePubInfo } from "@/hooks/useAppState";
import { ClickAwayListener } from "@mui/base";
import { useRouter } from "next/router";
import React, { useState } from "react";
import SignInMenu from "../SignInMenu/SignInMenu";
import { Button, Container, SignInMenuWrapper } from "./TopNav.styled";
import { useAnalytics } from "@/services/analyticsService";

const TopNav: React.FC = () => {
  const pubInfo = usePubInfo();
  const hasPubInfo = pubInfo?.email && pubInfo?.phone;
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const { trackEvent } = useAnalytics();

  if (!hasPubInfo) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container>
        {hasPubInfo ? (
          <div>
            <Button
              disableElevation
              onClick={() => {
                trackEvent({
                  category: "content",
                  action: "place obit cta",
                  label: window.location.href,
                });
                return router.push("/how-to-submit-obituary");
              }}
            >
              Place an Obituary
            </Button>
          </div>
        ) : (
          <div />
        )}
        <SignInMenuWrapper>
          <SignInMenu open={open} onToggle={() => setOpen(!open)} />
        </SignInMenuWrapper>
      </Container>
    </ClickAwayListener>
  );
};

export default TopNav;
