import styled from "@/components/contexts/ThemeProvider/styled";
import { Typography } from "@gannettdigital/community-hub-components";

export const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: 14,
  lineHeight: "28px",

  [theme.breakpoints.up("sm")]: {
    fontSize: 18
  }
}));

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(0, 2, 8),

  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(0, 12, 8)
  },

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 15, 8)
  },

  [theme.breakpoints.up("xl")]: {
    margin: theme.spacing(0, 40, 8)
  }
}));
