import { IImageFragment } from "@/graphql/fragments/__generated__/image.types";
import {
  CropMethod,
  getImageSrcWithFastlyParams, ImagePreview, parseCropParams, Typography
} from "@gannettdigital/community-hub-components";
import React from "react";
import { Dropzone, ImagesContainer } from "./UploadedMediaPreview.styled";

interface IProps {
  onImageDelete: (image: Partial<IImageFragment>) => void;
  onImageEdit: (image: Partial<IImageFragment>) => void;
  onImagesAdded: (images: File[]) => void;
  images: Partial<IImageFragment>[];
  showDropzone?: boolean;
}

const UploadedMediaPreview: React.FC<IProps> = ({
  onImageDelete,
  onImageEdit,
  onImagesAdded,
  images,
  showDropzone
}) => {
  if (images.length === 0 && !showDropzone) {
    return null;
  }

  return (
    <ImagesContainer>
      {images.map(image => {
        // TODO is this is valid
        const cropParams = parseCropParams(image.params || null);
        const cropMethod = image.id
          ? CropMethod.fastly
          : CropMethod.localCanvas;

        const imageSrc =
          cropMethod === CropMethod.fastly
            ? getImageSrcWithFastlyParams(image.uri || "", cropParams)
            : image.uri;

        return (
          <div key={image.uri}>
            <ImagePreview
              src={imageSrc || ""}
              cropParams={cropParams}
              cropMethod={cropMethod}
              onDeleteClick={() => onImageDelete(image)}
              onEditClick={() => onImageEdit(image)}
            />
          </div>
        );
      })}
      {showDropzone && (
        <div>
          <Dropzone dropzoneOptions={{ onDrop: onImagesAdded }}>
            <Typography>Add</Typography>
          </Dropzone>
        </div>
      )}
    </ImagesContainer>
  );
};

export default UploadedMediaPreview;
