import {
  Button,
  styled,
  Typography
} from "@gannettdigital/community-hub-components";

export const ServicesContent = styled("div")(({ theme }) => ({
  padding: "20px 30px",

  [theme.breakpoints.up("sm")]: {
    padding: "30px"
  }
}));

export const ServicesTypography = styled(Typography)(({ theme }) => ({
  marginBottom: 14,
  [theme.breakpoints.up("sm")]: {
    marginBottom: 18
  }
}));

export const ServicesHeading = styled(ServicesTypography)(({ theme }) => ({
  fontSize: 16,
  marginBottom: 14,
  [theme.breakpoints.up("sm")]: {
    fontSize: 18,
    marginBottom: 18
  }
}));

export const Container = styled("div")({
  marginBottom: 25
});

export const NestedContainer = styled(Container)({
  marginLeft: 25
});

export const FlexInputContainer = styled("div")(({ theme }) => ({
  "& > *": {
    marginBottom: 25
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    gap: "30px"
  }
}));

export const FlexContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "5px"
});

export const ButtonWithIcon = styled(FlexContainer)(({ theme }) => ({
  cursor: "pointer",

  "& .MuiTypography-root": {
    fontSize: 16,

    [theme.breakpoints.up("sm")]: {
      fontSize: 18
    }
  }
}));

export const EventTopContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

export const RemoveButton = styled(Button)(({ theme }) => ({
  marginBottom: "14px",

  [theme.breakpoints.up("sm")]: {
    marginBottom: "18px"
  }
}));

export const ModalContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(5),
  height: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between"
}));

export const Image = styled("img")(({ theme }) => ({
  margin: theme.spacing(10, 0),

  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(5, 0)
  }
}));
