import { useEffect, useState } from "react";

const useDebounce = (
  value: string,
  delay: number | undefined,
  setDebouncedValueCondition = true
) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (setDebouncedValueCondition) {
        setDebouncedValue(value);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [setDebouncedValueCondition, value, delay]);

  return debouncedValue;
};

export default useDebounce;
