import Image from "next/image";
import { CSSProperties, useState } from "react";

interface ImageRenderProps {
  alt?: string;
  src: string;
  width?: number;
  height?: number;
  objectFit?: CSSProperties["objectFit"];
  objectPosition?: CSSProperties["objectPosition"];
  className?: string;
}

/**
 * Image will be rendered within the set Width and Height to prevents Content Layout Shift
 * - This ignores next/image native optimizer. Load images from app public folder or any domain.
 * - Hides broken icon for a broken image.
 *
 * ## Why?:
 * Nextjs optimizer will extend the image URL into different versions and conflicts with `publicRuntimeConfig.assetsPrefix` that
 * changes based on the newspaper sites and environment. It will only work for dev env, and fails in prod/pre-prod.
 * - Nextjs's optimized URL:  https://www.indystar.com/obituaries/_next/image?url=%2Fgcdn%2Fcommunity-hub%2Fobits%2Fv0.8.98%2Fstatic%2Fimages%2Fcta%2Fsubmit-obituary-image-cta.jpg&w=384
 * - The correct one should be: https://www.indystar.com/gcdn/community-hub/obits/v0.8.98/static/images/cta/submit-obituary-image-cta.jpg?w=384
 *
 * ## Image Optimization:
 * - image optimization in this app should be done in Fastly.
 */
export const ImageRender = ({
  src,
  alt = "",
  width = 500,
  height = 500,
  objectFit = "contain",
  objectPosition = "center",
  className,
}: ImageRenderProps) => {
  const [imgSrc, setImgSrc] = useState<string>("");

  if (!src) return null;

  // using any Dom or native <img onError={onError} /> to detect image error will not work for Nextjs
  return (
    <div className={className} style={{ width, height, position: "relative" }}>
      {/* This acts as a loader to prevent showing broken image icon */}
      {!imgSrc && (
        <div
          aria-hidden
          style={{
            visibility: "hidden",
            width: 0,
            height: 0,
          }}
        >
          <Image
            alt={alt}
            src={src}
            width={width}
            height={height}
            style={{ objectFit, objectPosition }}
            onError={() => {
              console.log("Cannot load img src", src);
              setImgSrc(emptyPNG);
            }}
            onLoadingComplete={() => setImgSrc(src)}
            // Note:
            // `unoptimized` is required to load wildcard image host declared next.config.js `remotePatterns`
            // will only work for dev env, and fails in prod/pre-prod.
            // image optimization will be done in Fastly
            unoptimized
          />
        </div>
      )}

      <Image
        alt={alt}
        src={imgSrc || emptyPNG}
        width={width}
        height={height}
        style={{ objectFit, objectPosition }}
        // see the note above
        unoptimized
        // Using `placeholder`, `blurDataURL`, are clunky in this Nextjs version.
        // https://nextjs.org/docs/pages/api-reference/components/image
      />
    </div>
  );
};

const emptyPNG =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
