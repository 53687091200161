import { ImageWithLinearGradient } from "@/components/molecules";
import useAuth from "@/hooks/useAuth";
import { Container, Subtitle } from "./NoPicturesView.styled";

interface IProps {
  children?: React.ReactNode;
}

const NoPictures: React.FC<IProps> = ({ children }) => {
  const { authState } = useAuth();

  const Content = () =>
    // TODO: When DB is ready => check if the logged user is the owner
    authState?.isAuthenticatedInCurrentContext ? (
      <>
        <Subtitle textAlign="center">
          Create a visual memorial to share with others. There is no limit, so
          upload as many images as you want. If you don&apos;t have a lot of
          photos in hand right now, that&apos;s ok. You can always come back and
          add more later.
        </Subtitle>
        {children}
      </>
    ) : (
      <Subtitle textAlign="center">
        There are no photos to view at this time.
      </Subtitle>
    );

  return (
    <>
      <Container>
        <Content />
      </Container>
      <ImageWithLinearGradient fullHeight assetName="woman-in-park.svg" />
    </>
  );
};

export default NoPictures;
