// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IManageListObituaryFragment } from '../../fragments/__generated__/manageListObituary.types';
import { gql } from '@apollo/client';
import { ManageListObituaryFragmentDoc } from '../../fragments/__generated__/manageListObituary.types';
import * as Apollo from '@apollo/client';
export type IGetManageListForCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IGetManageListForCurrentUserQuery = (
  { __typename?: 'query_root' }
  & { obituary: Array<(
    { __typename?: 'obituary' }
    & IManageListObituaryFragment
  )> }
);


export const GetManageListForCurrentUserDocument = gql`
    query getManageListForCurrentUser {
  obituary(where: {is_owner: {_eq: true}}) {
    ...ManageListObituary
  }
}
    ${ManageListObituaryFragmentDoc}`;

/**
 * __useGetManageListForCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetManageListForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageListForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageListForCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManageListForCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<IGetManageListForCurrentUserQuery, IGetManageListForCurrentUserQueryVariables>) {
        return Apollo.useQuery<IGetManageListForCurrentUserQuery, IGetManageListForCurrentUserQueryVariables>(GetManageListForCurrentUserDocument, baseOptions);
      }
export function useGetManageListForCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetManageListForCurrentUserQuery, IGetManageListForCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<IGetManageListForCurrentUserQuery, IGetManageListForCurrentUserQueryVariables>(GetManageListForCurrentUserDocument, baseOptions);
        }
export type GetManageListForCurrentUserQueryHookResult = ReturnType<typeof useGetManageListForCurrentUserQuery>;
export type GetManageListForCurrentUserLazyQueryHookResult = ReturnType<typeof useGetManageListForCurrentUserLazyQuery>;
export type GetManageListForCurrentUserQueryResult = Apollo.QueryResult<IGetManageListForCurrentUserQuery, IGetManageListForCurrentUserQueryVariables>;