import styled from "@/components/contexts/ThemeProvider/styled";

export const EmailUsContainer = styled(
  (
    props: React.HTMLAttributes<HTMLDivElement> & {
      flexDirection: "row" | "column";
      marginBottom: string | number;
    }
  ) => <div {...props} />,
  {
    shouldForwardProp: prop =>
      prop !== "flexDirection" && prop !== "marginBottom"
  }
)(({ theme, flexDirection, marginBottom }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginBottom,

  [theme.breakpoints.up("sm")]: {
    flexDirection,
    gap: 5
  }
}));
