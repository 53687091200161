// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type IHistoricalObituaryEventFragment = (
  { __typename?: 'historical_obituary_event' }
  & Pick<Types.IHistorical_Obituary_Event, 'id' | 'type' | 'private' | 'date' | 'time' | 'location'>
);

export const HistoricalObituaryEventFragmentDoc = gql`
    fragment HistoricalObituaryEvent on historical_obituary_event {
  id
  type
  private
  date
  time
  location
}
    `;