import { IService_Duration_Type_Enum } from "@/graphql/__generated__/graphql.types";
import { IServiceFragment } from "@/graphql/fragments/__generated__/service.types";
import {
  AcceptModal,
  DatePicker,
  DrawerContent,
  Input,
  KeyOfTimezonesOffsetMap,
  MapboxAutocomplete,
  MapboxType,
  PlusCircleIcon,
  Select,
  TIMEZONES,
  TIMEZONES_OFFSETS,
  TimePicker,
  Typography,
  setUTCTimeToLocal
} from "@gannettdigital/community-hub-components";
import { yupResolver } from "@hookform/resolvers/yup";
import set from "date-fns/set";
import get from "lodash/get";
import isArray from "lodash/isArray";
import getConfig from "next/config";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm
} from "react-hook-form";
import {
  IExtendedServiceFragment,
  IServiceToDelete,
  IServicesForm,
  defaultService,
  validationSchema
} from "./Services.form";
import {
  ButtonWithIcon,
  Container,
  EventTopContainer,
  FlexContainer,
  FlexInputContainer,
  Image,
  NestedContainer,
  RemoveButton,
  ServicesContent,
  ServicesHeading,
  ServicesTypography
} from "./ServicesForm.styled";

const { publicRuntimeConfig } = getConfig();

interface IProps {
  onSubmit: (values: IExtendedServiceFragment[]) => void;
  onClose: () => void;
  onDelete: (id: string) => Promise<unknown>;
  defaultValues: IServiceFragment[];
}

const ServicesForm: React.FC<IProps> = ({
  onSubmit,
  onClose,
  onDelete,
  defaultValues
}) => {
  const [serviceToDelete, setServiceToDelete] =
    useState<IServiceToDelete | null>(null);

  const [showTimepickers, setShowTimepickers] = useState<boolean[]>([]);

  const hookFormMethods = useForm<IServicesForm>({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema)
  });

  const {
    setValue,
    handleSubmit,
    getValues,
    control,
    formState: { errors, defaultValues: formDefaultValues },
    reset
  } = hookFormMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
    keyName: "key"
  });

  useEffect(() => {
    if (isArray(defaultValues) && defaultValues.length > 0) {
      const initialShowTimepickersState: boolean[] = [];

      const initialFormValues = defaultValues.map(service => {
        return {
          ...service,
          service_durations: [
            ...service.service_durations.map(duration => {
              const isDateTimeType =
                duration.type === IService_Duration_Type_Enum.DateTime;
              const formattedBegin = setUTCTimeToLocal(duration.begin, duration.begin_offset);
              const formattedEnd = setUTCTimeToLocal(duration.end, duration.end_offset);

              initialShowTimepickersState.push(isDateTimeType);

              return {
                ...duration,
                begin: isDateTimeType ? formattedBegin : null,
                end: isDateTimeType ? formattedEnd : null,
                date: formattedBegin
                  ? set(new Date(formattedBegin), {
                      hours: 0,
                      minutes: 0,
                      seconds: 0
                    })
                  : null,
                // The Select component throws an error when the value is null
                begin_offset: duration.begin_offset || null,
                end_offset: duration.end_offset || null,
              };
            })
          ]
        };
      });

      reset({ services: initialFormValues });
      setShowTimepickers(initialShowTimepickersState);
    } else {
      reset({ services: [defaultService] });
    }
  }, [reset, defaultValues]);

  const mapboxOptions = {
    endpoint: publicRuntimeConfig.mapboxEndpoint,
    accessToken: publicRuntimeConfig.mapboxAccessToken
  };

  const handleRemoveEventFromForm = (index: number) => {
    remove(index);

    const newShowTimepickersState = [...showTimepickers];
    newShowTimepickersState.splice(index, 1);
    setShowTimepickers(newShowTimepickersState);
  };

  const handleResetCoordinates = (index: number) => {
    setValue(`services.${index}.longitude`, 0);
    setValue(`services.${index}.latitude`, 0);
  };

  return (
    <>
      <FormProvider {...hookFormMethods}>
        <form
          onSubmit={handleSubmit(({ services }) => onSubmit(services))}
          onKeyDown={e => {
            if (e.code === "Enter") e.preventDefault();
          }}
        >
          <DrawerContent title="Add Services" onClose={onClose}>
            <ServicesContent>
              {fields.map((field, index) => {
                return (
                  <div key={field.key}>
                    <EventTopContainer>
                      <ServicesHeading variant="h2">
                        Event {index + 1}
                      </ServicesHeading>
                      <RemoveButton
                        variant="underlined"
                        onClick={() => {
                          if (field.__typename) {
                            setServiceToDelete({
                              index,
                              id: field.id,
                              __typename: field.__typename
                            });
                          } else {
                            handleRemoveEventFromForm(index);
                          }
                        }}
                      >
                        Remove event
                      </RemoveButton>
                    </EventTopContainer>
                    <Container>
                      <Controller
                        control={control}
                        defaultValue={field.title || defaultService.title}
                        name={`services.${index}.title`}
                        render={props => (
                          <Input
                            {...props}
                            fullWidth
                            label="Title (ex: Visitation, funeral, burial, etc.)"
                            error={
                              !!get(errors, `services.${index}.title.message`)
                            }
                            helperText={get(
                              errors,
                              `services.${index}.title.message`,
                              ""
                            )}
                          />
                        )}
                      />
                    </Container>

                    {/* TODO: In future we are going to support multiple durations  */}
                    <Container>
                      <Controller
                        control={control}
                        defaultValue={
                          field.service_durations?.[0]?.date ||
                          defaultService.service_durations[0].date
                        }
                        name={`services.${index}.service_durations.0.date`}
                        render={({field: {value, onChange}}) => (
                          <DatePicker
                            value={value}
                            onChange={onChange}
                            label="Date (optional)"
                            textFieldProps={{
                              fontSize: 14,
                              error: !!get(
                                errors,
                                `services.${index}.service_durations[0].date`
                              ),
                              helperText: get(
                                errors,
                                `services.${index}.service_durations[0].date.message`,
                                ""
                              )
                            }}
                          />
                        )}
                      />
                    </Container>

                    {!showTimepickers[index] && (
                      <NestedContainer>
                        <ButtonWithIcon
                          onClick={() => {
                            const newShowTimepcikersState = [...showTimepickers];
                            newShowTimepcikersState[index] = true;
                            setShowTimepickers(newShowTimepcikersState);
                          }}
                        >
                          <PlusCircleIcon color="primary"/>
                          <Typography variant="body1">Add time</Typography>
                        </ButtonWithIcon>
                      </NestedContainer>
                    )}

                    {showTimepickers[index] && (
                      <FlexInputContainer>
                        <Controller
                          control={control}
                          defaultValue={
                            field.service_durations?.[0]?.begin ||
                            defaultService.service_durations[0].begin
                          }
                          name={`services.${index}.service_durations.0.begin`}
                          render={({field: {value, onChange}}) => (
                            <TimePicker
                              value={value}
                              onChange={onChange}
                              label="From (optional)"
                              textFieldProps={{
                                fontSize: 14,
                                error: !!get(
                                  errors,
                                  `services.${index}.service_durations.0.begin`
                                ),
                                helperText: get(
                                  errors,
                                  `services.${index}.service_durations.0.begin.message`,
                                  ""
                                )
                              }}
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          defaultValue={
                            field.service_durations?.[0]?.end ||
                            defaultService.service_durations[0].end
                          }
                          name={`services.${index}.service_durations.0.end`}
                          render={({field: {value, onChange}}) => (
                            <TimePicker
                              value={value}
                              onChange={onChange}
                              label="To (optional)"
                              textFieldProps={{
                                fontSize: 14,
                                error: !!get(
                                  errors,
                                  `services.${index}.service_durations.0.end`
                                ),
                                helperText: get(
                                  errors,
                                  `services.${index}.service_durations.0.end.message`,
                                  ""
                                )
                              }}
                            />
                          )}
                        />

                      <Controller
                        control={control}
                        defaultValue={
                          field.service_durations?.[0]?.begin_offset ||
                          defaultService.service_durations[0].begin_offset
                        }
                        name={`services.${index}.service_durations.0.begin_offset`}
                        render={({field: {value, onChange}}) => (
                          <Select
                            value={value}
                            onChange={(ev) => {
                              onChange(ev.target.value);
                              setValue(`services.${index}.service_durations.0.begin_offset`, ev.target.value ? Number(ev.target.value) : null);
                              setValue(`services.${index}.service_durations.0.end_offset`, ev.target.value ? Number(ev.target.value) : null);
                            }}
                            label="Time zone"
                            error={!!get(
                              errors,
                              `services.${index}.service_durations.0.begin_offset`
                            )}
                            helperText={get(
                              errors,
                              `services.${index}.service_durations.0.begin_offset.message`,
                              ''
                            )}
                            options={Object.keys(
                              TIMEZONES
                              ).map((timezone) => ({
                                label: timezone,
                                value: TIMEZONES_OFFSETS.get(timezone as KeyOfTimezonesOffsetMap) as number,
                              }))
                              }
                            />
                          )}
                        />
                      </FlexInputContainer>
                    )}
                    <ServicesHeading variant="h2">
                      Venue information
                    </ServicesHeading>

                    <Container>
                      <Controller
                        name={`services.${index}.name`}
                        defaultValue={
                          (field.name || defaultService.name) ??
                          formDefaultValues?.services?.[index]?.name
                        }
                        control={control}
                        render={props => (
                          <MapboxAutocomplete
                            autoCompleteProps={props}
                            disableClearable
                            inputProps={{
                              label: "Name of venue",
                              error: !!get(errors, `services.${index}.name`),
                              helperText: get(
                                errors,
                                `services.${index}.name.message`,
                                ""
                              )
                            }}
                            mapboxOptions={{
                              ...mapboxOptions,
                              type: MapboxType.poi
                            }}
                            onInputChangeCallback={() => {
                              const lat = get(
                                getValues(),
                                `services.${index}.latitude`,
                                0
                              );
                              const long = get(
                                getValues(),
                                `services.${index}.latitude`,
                                0
                              );
                              const address1 = get(
                                getValues(),
                                `services.${index}.address1`,
                                ""
                              );

                              if (!lat && !long && !address1) {
                                handleResetCoordinates(index);
                              }
                            }}
                            onChangeCallback={data => {
                              setValue(
                                `services.${index}.longitude`,
                                data.longitude
                              );
                              setValue(
                                `services.${index}.latitude`,
                                data.latitude
                              );
                              setValue(
                                `services.${index}.address1`,
                                data.address
                              );
                              setValue(
                                `services.${index}.country`,
                                data.country
                              );
                              setValue(`services.${index}.city`, data.city);
                              setValue(`services.${index}.state`, data.state);
                              setValue(`services.${index}.zip`, data.zip);
                            }}
                          />
                        )}
                      />
                    </Container>

                    <Container>
                      <Controller
                        name={`services.${index}.address1`}
                        defaultValue={
                          (field.address1 || defaultService.address1) ??
                          formDefaultValues?.services?.[index]?.address1
                        }
                        control={control}
                        render={props => (
                          <MapboxAutocomplete
                            autoCompleteProps={props}
                            disableClearable
                            inputProps={{
                              label: "Street address",
                              error: !!get(errors, `services.${index}.address1`),
                              helperText: get(
                                errors,
                                `services.${index}.address1.message`,
                                ""
                              )
                            }}
                            mapboxOptions={{
                              ...mapboxOptions,
                              type: MapboxType.address
                            }}
                            onInputChangeCallback={() => {
                              handleResetCoordinates(index);
                            }}
                            onChangeCallback={data => {
                              setValue(
                                `services.${index}.longitude`,
                                data.longitude
                              );
                              setValue(
                                `services.${index}.latitude`,
                                data.latitude
                              );
                              setValue(
                                `services.${index}.country`,
                                data.country
                              );
                              setValue(`services.${index}.city`, data.city);
                              setValue(`services.${index}.state`, data.state);
                              setValue(`services.${index}.zip`, data.zip);
                            }}
                          />
                        )}
                      />
                    </Container>

                    <Container>
                      <Controller
                        control={control}
                        defaultValue={field.address2 || defaultService.address2}
                        name={`services.${index}.address2`}
                        render={props => (
                          <Input
                            {...props}
                            fullWidth
                            label="Street address 2"
                            error={
                              !!get(errors, `services.${index}.address2.message`)
                            }
                            helperText={get(
                              errors,
                              `services.${index}.address2.message`,
                              ""
                            )}
                          />
                        )}
                      />
                    </Container>

                    <FlexInputContainer>
                      <Controller
                        name={`services.${index}.city`}
                        defaultValue={
                          (field.city || defaultService.city) ??
                          formDefaultValues?.services?.[index]?.city
                        }
                        control={control}
                        render={props => (
                          <MapboxAutocomplete
                            autoCompleteProps={props}
                            disableClearable
                            inputProps={{
                              label: "City",
                              error: !!get(errors, `services.${index}.city`),
                              helperText: get(
                                errors,
                                `services.${index}.city.message`,
                                ""
                              )
                            }}
                            mapboxOptions={{
                              ...mapboxOptions,
                              type: MapboxType.place
                            }}
                            onInputChangeCallback={() => {
                              handleResetCoordinates(index);
                            }}
                            onChangeCallback={data => {
                              handleResetCoordinates(index);
                              setValue(
                                `services.${index}.country`,
                                data.country
                              );
                              setValue(`services.${index}.state`, data.state);
                              setValue(`services.${index}.zip`, data.zip);
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={`services.${index}.state`}
                        defaultValue={
                          (field.state || defaultService.state) ??
                          formDefaultValues?.services?.[index]?.state
                        }
                        control={control}
                        render={props => (
                          <MapboxAutocomplete
                            autoCompleteProps={props}
                            disableClearable
                            inputProps={{
                              label: "State",
                              error: !!get(errors, `services.${index}.state`),
                              helperText: get(
                                errors,
                                `services.${index}.state.message`,
                                ""
                              )
                            }}
                            mapboxOptions={{
                              ...mapboxOptions,
                              type: MapboxType.region
                            }}
                            onInputChangeCallback={() => {
                              handleResetCoordinates(index);
                            }}
                            onChangeCallback={data => {
                              handleResetCoordinates(index);

                              setValue(
                                `services.${index}.country`,
                                data.country
                              );
                              setValue(`services.${index}.zip`, data.zip);
                            }}
                          />
                        )}
                      />
                    </FlexInputContainer>

                    <FlexInputContainer>
                      <Controller
                        control={control}
                        defaultValue={field.zip || defaultService.zip}
                        name={`services.${index}.zip`}
                        render={props => (
                          <Input
                            {...props}
                            fullWidth
                            label="Zip code"
                            error={
                              !!get(errors, `services.${index}.zip.message`)
                            }
                            helperText={get(
                              errors,
                              `services.${index}.zip.message`,
                              ""
                            )}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        defaultValue={field.country || defaultService.country}
                        name={`services.${index}.country`}
                        render={props => (
                          <Input
                            {...props}
                            fullWidth
                            label="Country"
                            error={
                              !!get(errors, `services.${index}.country.message`)
                            }
                            helperText={get(
                              errors,
                              `services.${index}.country.message`,
                              ""
                            )}
                          />
                        )}
                      />
                    </FlexInputContainer>

                    <Container>
                      <Controller
                        control={control}
                        defaultValue={field.notes || defaultService.notes}
                        name={`services.${index}.notes`}
                        render={props => (
                          <Input
                            {...props}
                            fullWidth
                            label="Note (optional)"
                            error={
                              !!get(errors, `services.${index}.notes.message`)
                            }
                            helperText={get(
                              errors,
                              `services.${index}.notes.message`,
                              ""
                            )}
                          />
                        )}
                      />
                    </Container>

                    <FlexContainer>
                      <ServicesHeading variant="h2">
                        Streaming information
                      </ServicesHeading>
                      <ServicesTypography variant="body2">
                        (optional)
                      </ServicesTypography>
                    </FlexContainer>

                    <Container>
                      <Controller
                        control={control}
                        defaultValue={
                          field.external_uri || defaultService.external_uri
                        }
                        name={`services.${index}.external_uri`}
                        render={props => (
                          <Input
                            {...props}
                            fullWidth
                            label="Streaming link"
                            error={
                              !!get(
                                errors,
                                `services.${index}.external_uri.message`
                              )
                            }
                            helperText={get(
                              errors,
                              `services.${index}.external_uri.message`,
                              ""
                            )}
                          />
                        )}
                      />
                    </Container>
                  </div>
                );
              })}
              <ButtonWithIcon
                onClick={() => {
                  append(defaultService);
                  setShowTimepickers(prevDates => [...prevDates, false]);
                }}
              >
                <PlusCircleIcon color="primary" />
                <Typography variant="body1">Add another event</Typography>
              </ButtonWithIcon>
            </ServicesContent>
          </DrawerContent>
        </form>
      </FormProvider>

      <AcceptModal
        open={!!serviceToDelete}
        acceptLabel="Delete"
        onClose={() => setServiceToDelete(null)}
        title="Are you sure?"
        subtitle="Deleting a service cannot be undone."
        subtitle2="Do you want to proceed?"
        onAccept={() => {
          if (serviceToDelete && serviceToDelete.id) {
            onDelete(serviceToDelete.id).then(() => {
              handleRemoveEventFromForm(serviceToDelete.index);
              setServiceToDelete(null);
            });
          }
        }}
      >
        <Image
          alt="services"
          width="300"
          src={`${publicRuntimeConfig.assetsPrefix}/static/images/woman-with-flowers.svg`}
        />
      </AcceptModal>
    </>
  );
};

export default ServicesForm;
