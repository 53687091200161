import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { serifFontFamily } from "@gannettdigital/community-hub-components";

export const Container = styled("div")(({ theme }) => ({
  border: `6px solid ${theme.palette.primary.main}`,
  color: Colors.gray2,
  fontFamily: serifFontFamily,
  fontSize: 14,
  fontStyle: "italic",
  fontWeight: 700,
  margin: "0 auto",
  maxWidth: "300px",
  padding: "14px 16px 16px"
}));

export const Underline = styled("span")(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.primary.main}`
}));
