// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetDisplayCityQueryVariables = Types.Exact<{
  term?: Types.Maybe<Types.Scalars['String']>;
  id: Types.Scalars['uuid'];
}>;


export type IGetDisplayCityQuery = (
  { __typename?: 'query_root' }
  & { display_obituary: Array<(
    { __typename?: 'display_obituary' }
    & Pick<Types.IDisplay_Obituary, 'city'>
  )> }
);


export const GetDisplayCityDocument = gql`
    query getDisplayCity($term: String = null, $id: uuid!) {
  display_obituary(
    where: {display_obituary_publications: {publication_id: {_eq: $id}}, city: {_ilike: $term, _is_null: false, _gt: "1"}}
    distinct_on: [city]
    order_by: [{city: asc}]
  ) {
    city
  }
}
    `;

/**
 * __useGetDisplayCityQuery__
 *
 * To run a query within a React component, call `useGetDisplayCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayCityQuery({
 *   variables: {
 *      term: // value for 'term'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDisplayCityQuery(baseOptions: Apollo.QueryHookOptions<IGetDisplayCityQuery, IGetDisplayCityQueryVariables>) {
        return Apollo.useQuery<IGetDisplayCityQuery, IGetDisplayCityQueryVariables>(GetDisplayCityDocument, baseOptions);
      }
export function useGetDisplayCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDisplayCityQuery, IGetDisplayCityQueryVariables>) {
          return Apollo.useLazyQuery<IGetDisplayCityQuery, IGetDisplayCityQueryVariables>(GetDisplayCityDocument, baseOptions);
        }
export type GetDisplayCityQueryHookResult = ReturnType<typeof useGetDisplayCityQuery>;
export type GetDisplayCityLazyQueryHookResult = ReturnType<typeof useGetDisplayCityLazyQuery>;
export type GetDisplayCityQueryResult = Apollo.QueryResult<IGetDisplayCityQuery, IGetDisplayCityQueryVariables>;