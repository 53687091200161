import {
  styled,
  Dropzone as CustomDropzone,
  DropzoneProps,
  PhotoFilledIcon as CustomPhotoFilledIcon,
  Typography,
  Button
} from "@gannettdigital/community-hub-components";

export const ImagesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",

  "& > *": {
    height: "300px",
    flexBasis: 1
  },

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",

    "& > *": {
      flexGrow: 1
    }
  }
}));

export const PhotoFilledIcon = styled(CustomPhotoFilledIcon)(() => ({
  marginTop: "81px",
  fontSize: "60px"
}));

export const Dropzone = styled((props: DropzoneProps) => (
  <CustomDropzone {...props}>
    <PhotoFilledIcon color="primary" />
    <Typography
      variant="body2"
      color="primary"
      fontWeight="bold"
      marginBottom="12px"
    >
      + Drop photos here
    </Typography>
    <Button variant="outlined">Or click to select</Button>
  </CustomDropzone>
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxSizing: "border-box",
  height: "inherit",

  border: `1px solid ${theme.palette.all.gray10}`,

  "&:hover": {
    borderWidth: "1px",
    borderStyle: "solid"
  }
}));
