import { SubmitPageTitle as Title } from "@/components/atoms";
import { EmailUsText } from "@/components/molecules";
import { usePubInfo } from "@/hooks/useAppState";
import {
  Accordion,
  Typography,
} from "@gannettdigital/community-hub-components";
import getConfig from "next/config";
import React from "react";
import {
  HOWTO_PAGE_TYPE,
  useAnalytics,
} from "../../../services/analyticsService";
import {
  AccordionContainer,
  Image,
  LeftColumn,
  ProcessContainer,
  TextContainer,
} from "./SubmitPageProcessSection.styled";

const { publicRuntimeConfig } = getConfig();

const ProcessSection: React.FC = () => {
  const { deadlines } = usePubInfo();
  const { trackEvent } = useAnalytics();

  return (
    <ProcessContainer>
      <div>
        <Title variant="body2">How it works</Title>
        <TextContainer>
          <Typography
            fontSize={{ xs: 16, sm: 18 }}
            variant="body2"
            mb={{ xs: 4, sm: 2, md: 4 }}
          >
            We understand that life sometimes takes us beyond our hometown and
            that&apos;s why we have made it easy for you to publish your loved
            one&apos;s story in any of our 260 publications.
          </Typography>
        </TextContainer>
        <AccordionContainer>
          <Accordion
            items={[
              {
                title: "Process",
                content:
                  "Once we have received your email with all the information listed above, we will create the obituary and email back what it will look like in print and e-edition, a price quote and deadline for payment. Please note payment must be received prior to deadline to print on the date(s) requested. Once the deadline has passed, the obituary can no longer be changed, cancelled or refunded. Payments can be made with our obituary advisors over the phone. Once you have paid for the obituary you can expect to see it posted online before print and e-edition publication. We reserve the right to edit, refuse, reject any content before final approval.",
              },
              {
                title: "Verification",
                content:
                  "Before we can publish the obituary, we must verify the passing of the deceased with a licensed party for our records. If you are not working with a funeral home, the name and number of a mortuary, crematorium, or medical donation program will also be sufficient. If you do not have that information, we can also accept a scanned death certificate. The information provided for verification purposes will remain private and will not published in the obituary.",
              },
              ...(deadlines
                ? [{ title: "Deadlines", content: deadlines }]
                : []),
            ]}
            onChange={(event, item, state) => {
              if (state === "open") {
                trackEvent({
                  action: "expand",
                  category: "submit",
                  label: item.title && item.title.toLowerCase(),
                  pageType: HOWTO_PAGE_TYPE,
                });
              }
            }}
          />
        </AccordionContainer>
      </div>
      <LeftColumn>
        <Image
          alt="How it works"
          src={`${publicRuntimeConfig.assetsPrefix}/static/images/sitting-woman.svg`}
          width={300}
          height={245}
        />

        <EmailUsText label="email2" />
      </LeftColumn>
    </ProcessContainer>
  );
};

export default ProcessSection;
