// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type IObituaryTagFragment = (
  { __typename?: 'obituary_tag' }
  & { tag: (
    { __typename?: 'tag' }
    & { web_config_filter_tags: Array<(
      { __typename?: 'web_config_filter_tags' }
      & { web_config_filter: (
        { __typename?: 'web_config_filter' }
        & Pick<Types.IWeb_Config_Filter, 'name'>
      ) }
    )> }
  ) }
);

export const ObituaryTagFragmentDoc = gql`
    fragment ObituaryTag on obituary_tag {
  tag {
    web_config_filter_tags {
      web_config_filter {
        name
      }
    }
  }
}
    `;