import { Link } from "@/components/atoms";
import { usePubInfo } from "@/hooks/useAppState";
import { HOWTO_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { Typography } from "@gannettdigital/community-hub-components";
import React from "react";
import { EmailUsContainer } from "./EmailUsText.styled";

interface IProps {
  flexDirection?: "row" | "column";
  linkFontSize?: { md: string | number; lg: string | number };
  marginBottom?: string | number;
  label?: string;
}

const EmailUsText: React.FC<IProps> = ({
  label = "",
  flexDirection = "column",
  linkFontSize = { md: 16, lg: 16 },
  marginBottom = 0,
}) => {
  const { email } = usePubInfo();
  const { trackEvent } = useAnalytics();

  return (
    <EmailUsContainer flexDirection={flexDirection} marginBottom={marginBottom}>
      <Typography
        textAlign="center"
        fontSize={{ xs: 16, md: 18, lg: 24 }}
        fontWeight="bold"
        variant="body2"
      >
        Email us to get started:
      </Typography>
      <Link
        href={`mailto:${email}`}
        rel="noreferrer"
        target="_blank"
        fontSize={linkFontSize}
        onClick={() => {
          trackEvent({
            action: "click",
            category: "submit",
            label,
            pageType: HOWTO_PAGE_TYPE,
          });
        }}
      >
        {email}
      </Link>
    </EmailUsContainer>
  );
};

export default EmailUsText;
