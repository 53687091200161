import { Skeleton } from "@mui/material";

const ObituaryFormSkeleton = () => {
  return (
    <div>
      <Skeleton height="30px" width="30%" />
      <div style={{ display: "flex", gap: "10px" }}>
        <Skeleton height="60px" width="50%" />
        <Skeleton height="60px" width="50%" />
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <Skeleton height="60px" width="50%" />
        <Skeleton height="60px" width="50%" />
      </div>
      <Skeleton height="30px" width="30%" />
      <Skeleton height="60px" />
      <Skeleton height="30px" width="30%" />
      <Skeleton height="60px" />
      <Skeleton height="30px" width="30%" />
      <div style={{ display: "flex", gap: "10px" }}>
        <Skeleton height="60px" width="50%" />
        <Skeleton height="60px" width="50%" />
      </div>
      <Skeleton height="60px" />
      <Skeleton height="30px" width="30%" />
      <Skeleton height="300px" width="100%" />
      <Skeleton height="30px" width="30%" />
      <Skeleton height="60px" />
    </div>
  );
};

export default ObituaryFormSkeleton;
