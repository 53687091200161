import { IFromSalesOrderFragment } from "@/graphql/fragments/__generated__/fromSalesOrder.types";
import { useDomain } from "@/hooks/useAppState";
import {
    Checkbox,
    Input,
    Link,
    MapboxAutocomplete,
    MapboxType,
    Typography
} from "@gannettdigital/community-hub-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, FormGroup } from "@mui/material";
import { get } from "lodash";
import getConfig from "next/config";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FlexInputContainer } from "../ObituaryForm/ObituaryForm.styled";
import {
    FORM_DEFAULT_VALUES,
    validationSchema
} from "./ContactAndVerificationForm.form";
import { FormHeading } from "./ContactAndVerificationForm.styled";

const { publicRuntimeConfig } = getConfig();

const FORM_ID = "contact-and-verification-form";

interface IProps {
  onSubmit: SubmitHandler<IFromSalesOrderFragment>;
  defaultValues?: IFromSalesOrderFragment;
  formId?: string;
}

const ContactAndVerificationForm: React.FC<IProps> = ({
  onSubmit,
  defaultValues = FORM_DEFAULT_VALUES,
  formId = FORM_ID
}) => {
  const domain = useDomain();

  const hookFormMethods = useForm<IFromSalesOrderFragment>({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(validationSchema)
  });
  const { handleSubmit, setValue, control, formState: { errors } } = hookFormMethods;

  const mapboxOptions = {
    endpoint: publicRuntimeConfig.mapboxEndpoint,
    accessToken: publicRuntimeConfig.mapboxAccessToken
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <FormHeading variant="h2">Contact Information</FormHeading>
      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.contact_firstname}
          name="contact_firstname"
          render={props => (
            <Input
              {...props}
              fullWidth
              required
              label="Your first name"
              error={!!get(errors, "contact_firstname", false)}
              helperText={get(errors, "contact_firstname.message")}
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={defaultValues.contact_lastname}
          name="contact_lastname"
          render={props => (
            <Input
              {...props}
              fullWidth
              required
              label="Your last name"
              error={!!get(errors, "contact_lastname", false)}
              helperText={get(errors, "contact_lastname.message")}
            />
          )}
        />
      </FlexInputContainer>
      <FormHeading variant="h2">Billing Address</FormHeading>
      <FlexInputContainer>
        <Controller
          defaultValue={defaultValues.billing_address}
          name="billing_address"
          control={control}
          render={props => (
            <MapboxAutocomplete
              autoCompleteProps={props}
              disableClearable
              inputProps={{
                required: true,
                label: "Street address",
                error: !!get(errors, "billing_address", false),
                helperText: get(errors, "billing_address.message")
              }}
              mapboxOptions={{
                ...mapboxOptions,
                type: MapboxType.address
              }}
              onChangeCallback={data => {
                setValue(`billing_city`, data.city);
                setValue(`billing_state`, data.state);
                setValue(`billing_zip`, data.zip);
              }}
            />
          )}
        />
      </FlexInputContainer>

      <FlexInputContainer>
        <Controller
          defaultValue={defaultValues.billing_city}
          name="billing_city"
          control={control}
          render={props => (
            <MapboxAutocomplete
              autoCompleteProps={props}
              disableClearable
              inputProps={{
                required: true,
                label: "City",
                error: !!get(errors, "billing_city", false),
                helperText: get(errors, "billing_city.message")
              }}
              mapboxOptions={{
                ...mapboxOptions,
                type: MapboxType.place
              }}
              onChangeCallback={data => {
                setValue(`billing_state`, data.state);
                setValue(`billing_zip`, data.zip);
              }}
            />
          )}
        />

        <Controller
          defaultValue={defaultValues.billing_state}
          name="billing_state"
          control={control}
          render={props => (
            <MapboxAutocomplete
              autoCompleteProps={props}
              disableClearable
              inputProps={{
                required: true,
                label: "State",
                error: !!get(errors, "billing_state", false),
                helperText: get(errors, "billing_state.message")
              }}
              mapboxOptions={{
                ...mapboxOptions,
                type: MapboxType.region
              }}
              onChangeCallback={data => {
                setValue(`billing_zip`, data.zip);
              }}
            />
          )}
        />
      </FlexInputContainer>
      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.billing_zip}
          name="billing_zip"
          render={props => (
            <Input
              {...props}
              fullWidth
              required
              label="Zip code"
              error={!!get(errors, "billing_zip", false)}
              helperText={get(errors, "billing_zip.message")}
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={defaultValues.billing_phone}
          name="billing_phone"
          render={props => (
            <Input
              {...props}
              fullWidth
              required
              label="Phone number"
              error={!!get(errors, "billing_phone", false)}
              helperText={get(errors, "billing_phone.message")}
            />
          )}
        />
      </FlexInputContainer>

      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.billing_email}
          name="billing_email"
          render={props => (
            <Input
              {...props}
              fullWidth
              required
              label="Email address"
              error={!!get(errors, "billing_email", false)}
              helperText={get(errors, "billing_email.message")}
            />
          )}
        />
      </FlexInputContainer>

      <FormHeading variant="h2">Verification</FormHeading>
      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.verification_provider}
          name="verification_provider"
          render={props => (
            <Input
              {...props}
              fullWidth
              required
              label="Care provider (ex: funeral home, mortuary, crematorium, physician)"
              error={!!get(errors, "verification_provider", false)}
              helperText={get(errors, "verification_provider.message")}
            />
          )}
        />
      </FlexInputContainer>

      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.verification_contact}
          name="verification_contact"
          render={props => (
            <Input
              {...props}
              fullWidth
              required
              label="Name of person to contact"
              error={!!get(errors, "verification_contact", false)}
              helperText={get(errors, "verification_contact.message")}
            />
          )}
        />
      </FlexInputContainer>

      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.verification_phone}
          name="verification_phone"
          render={props => (
            <Input
              {...props}
              fullWidth
              required
              label="Phone number"
              error={!!get(errors, "verification_phone", false)}
              helperText={get(errors, "verification_phone.message")}
            />
          )}
        />
      </FlexInputContainer>
      <Divider />
      <FormGroup>
        <Controller
          control={control}
          defaultValue={defaultValues.have_accepted_terms_and_service}
          name="have_accepted_terms_and_service"
          render={({ field:{name, onChange, value, ref} }) => (
            <Checkbox
              checkboxProps={{
                name,
                onChange: e => onChange(e.target.checked),
                required: true,
                value,
                ref
              }}
              labelProps={{
                label: (
                  <Typography variant="body2" fontSize={18}>
                    I agree and accept the{" "}
                    <Link
                      variant="h4"
                      fontSize={18}
                      href={`${domain}/terms`}
                      target="_blank"
                    >
                      Terms of Service
                    </Link>
                  </Typography>
                )
              }}
            />
          )}
        />
      </FormGroup>
    </form>
  );
};

export default ContactAndVerificationForm;
