import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { serifFontFamily } from "@gannettdigital/community-hub-components";
import { Typography } from "@mui/material";

export const Container = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(5.25)
}));

export const DisplayOptionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: theme.spacing(2),
  justifyContent: "space-between",

  [theme.breakpoints.up("sm")]: {
    marginTop: 0,
    justifyContent: "flex-start"
  }
}));

export const OptionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.up("sm")]: {
    marginRight: "47px !important",
    justifyContent: "flex-start"
  }
}));

export const MobileDrawerWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%"
});

export const DesktopSearchWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export const DisplayViewIconsWrapper = styled("div")({
  display: "flex",
  width: 97,
  justifyContent: "space-between"
});

export const OptionText = styled(Typography)(({ theme }) => ({
  display: "none",
  whiteSpace: "nowrap",
  marginRight: `${theme.spacing(1)}!important`,
  fontFamily: `${serifFontFamily} !important`,
  fontSize: "18px !important",
  color: Colors.gray2,
  fontStyle: "italic",

  [theme.breakpoints.up("sm")]: {
    display: "block"
  }
}));

export const StepperContainer = styled("div")(({ theme }) => ({
  marginTop: 0,

  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(1.5)
  }
}));

export const DesktopSearchContainer = styled("div")(({ theme }) => ({
  display: "none",
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    display: "block"
  }
}));

export const MobileSearchContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    display: "none"
  }
}));
