// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IHistoricalObituaryEventFragment } from './historicalObituaryEvent.types';
import { gql } from '@apollo/client';
import { HistoricalObituaryEventFragmentDoc } from './historicalObituaryEvent.types';
export type IHistoricalObituaryFragment = (
  { __typename?: 'historical_obituary' }
  & Pick<Types.IHistorical_Obituary, 'id' | 'slug' | 'state' | 'subtitle' | 'title' | 'first_name' | 'last_name' | 'middle_name' | 'external_published_at' | 'external_updated_at' | 'external_ran_from' | 'body' | 'city' | 'date_born' | 'date_deceased' | 'logos'>
  & { funeralHome?: Types.Maybe<(
    { __typename?: 'funeral_home' }
    & Pick<Types.IFuneral_Home, 'external_location_id' | 'name'>
  )>, historical_obituary_events: Array<(
    { __typename?: 'historical_obituary_event' }
    & IHistoricalObituaryEventFragment
  )> }
);

export const HistoricalObituaryFragmentDoc = gql`
    fragment HistoricalObituary on historical_obituary {
  id
  slug
  state
  subtitle
  title
  first_name
  last_name
  middle_name
  external_published_at
  external_updated_at
  external_ran_from
  body
  city
  date_born
  date_deceased
  logos
  funeralHome {
    external_location_id
    name
  }
  historical_obituary_events {
    ...HistoricalObituaryEvent
  }
}
    ${HistoricalObituaryEventFragmentDoc}`;