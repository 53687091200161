import { useDomain } from "@/hooks/useAppState";
import { INDEX_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { Link } from "@gannettdigital/community-hub-components";
import React from "react";
import { Container, Underline } from "./FormStackCTA.styled";
interface IProps {
  mobile?: boolean;
}

const FormStack: React.FC<IProps> = ({ mobile }) => {
  const domain = useDomain();
  const { trackEvent } = useAnalytics();
  let url = "";

  if (domain) {
    url = `https://cm.${domain}/site-feedback/?platform=Tangent-${
      mobile ? "MW" : "Desktop"
    }&url=https%3A%2F%2Fwww.${domain}%2F&marketName=${domain.replace(
      ".com",
      ""
    )}&cta=Right-Rail`;
  }

  return (
    <Link
      onClick={() =>
        trackEvent({
          action: "cta|formstack",
          label: url,
          pageType: INDEX_PAGE_TYPE,
        })
      }
      href={url}
      target="_blank"
      rel="noopener"
      underline="none"
      color="primary"
    >
      <Container>
        We&apos;re always working to improve your experience.&nbsp;
        <Underline>Let us know what you think.</Underline>
      </Container>
    </Link>
  );
};

export default FormStack;
