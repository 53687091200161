import { EmailUsText } from "@/components/molecules/";
import React from "react";
import { EndContainer } from "./SubmitPageEndSection.styled";

const EndSection: React.FC = () => {
  return (
    <EndContainer>
      <EmailUsText label="email3" />
    </EndContainer>
  );
};

export default EndSection;
