import { IFromSalesOrderFragment } from "@/graphql/fragments/__generated__/fromSalesOrder.types";
import { IUserCreatedObituaryFragment } from "@/graphql/fragments/__generated__/userCreatedObituary.types";
import { useConfig, useWebConfigFilterTags } from "@/hooks/useAppState";
import {
  Drawer,
  DrawerContent
} from "@gannettdigital/community-hub-components";
import React from "react";
import { ContactAndVerificationForm } from "..";
import ObituaryForm from "../ObituaryForm/ObituaryForm";
import ObituaryFormSkeleton from "../ObituaryForm/ObituaryForm.skeleton";
import { Container, Tab, TabPanel, Tabs } from "./ObituaryDrawer.styled";
interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    obituary: IUserCreatedObituaryFragment,
    fromSalesOrder: IFromSalesOrderFragment
  ) => void;
  obituary?: IUserCreatedObituaryFragment;
  loadingData?: boolean;
  loadingSubmit?: boolean;
}

const YLO_FORM_ID = "your-loved-one-form";
const CAV_FORM_ID = "contact-and-verification-form";

const TABS = {
  your_loved_one: 0,
  contact_and_verification: 1
};

const ObituaryDrawer: React.FC<IProps> = ({
  open,
  onClose,
  onSubmit,
  obituary,
  loadingData,
  loadingSubmit
}) => {
  const webConfigFilterTags = useWebConfigFilterTags();
  const config = useConfig();

  const [activeTab, setActiveTab] = React.useState(TABS.your_loved_one);
  const [formObituary, setFormObituary] =
    React.useState<IUserCreatedObituaryFragment | undefined>(obituary);

  const handleTabChange = (_: React.SyntheticEvent, selectedTab: number) => {
    if (activeTab === TABS.your_loved_one) {
      return;
    }
    setActiveTab(selectedTab);
  };

  const handleYourLovedOneFormSubmit = (
    formValues: IUserCreatedObituaryFragment
  ) => {
    setFormObituary(formValues);
    setActiveTab(TABS.contact_and_verification);
  };

  const handleContactAndVerificationFormSubmit = (
    formValues: IFromSalesOrderFragment
  ) => {
    if (!formObituary) {
      console.error("Contact and verification form submitted but no obituary data found");
      return;
    }

    onSubmit(formObituary, formValues);
  };

  return (
    <Drawer open={open}>
      <DrawerContent
        title="Edit Life Story"
        onClose={onClose}
        submitButtonProps={{
          disabled: loadingSubmit,
          form: activeTab === TABS.your_loved_one ? YLO_FORM_ID : CAV_FORM_ID
        }}
        submitButtonLabel={
          activeTab === TABS.contact_and_verification // if last step = finish, else Save & Cont.
            ? "Finish"
            : "Save and Continue"
        }
      >
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            centered
            aria-label="scrollable force tabs example"
          >
            <Tab value={TABS.your_loved_one} label="Your loved one" />
            <Tab
              value={TABS.contact_and_verification}
              label="Contact and verification"
              form={YLO_FORM_ID}
              type="submit"
            />
          </Tabs>
          <Container>
            <TabPanel index={TABS.your_loved_one} value={activeTab}>
              {loadingData ? (
                <ObituaryFormSkeleton />
              ) : (
                <ObituaryForm
                  onSubmit={handleYourLovedOneFormSubmit}
                  printPublications={webConfigFilterTags}
                  defaultValues={obituary}
                  formId={YLO_FORM_ID}
                />
              )}
            </TabPanel>

            <TabPanel index={TABS.contact_and_verification} value={activeTab}>
              <ContactAndVerificationForm
                onSubmit={handleContactAndVerificationFormSubmit}
                defaultValues={
                  obituary &&
                  obituary.from_sales_orders &&
                  obituary.from_sales_orders[0]
                }
                formId={CAV_FORM_ID}
              />
            </TabPanel>
          </Container>
        </>
      </DrawerContent>
    </Drawer>
  );
};

export default ObituaryDrawer;
