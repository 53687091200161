import { Link, Typography } from "@gannettdigital/community-hub-components";

import getConfig from "next/config";

import React from "react";
import { Container, RightSide, SearchIcon } from "./SubNav.styled";

interface IProps {
  handleClickSearchIcon?: () => void;
}
const { publicRuntimeConfig } = getConfig();

const BASE_PATH = publicRuntimeConfig.BASE_PATH;

const SubNav: React.FC<IProps> = ({ handleClickSearchIcon = () => {} }) => {
  return (
    <>
      <Container>
        <Typography variant="h1">
          <Link href={BASE_PATH} color="inherit" underline="none" variant="h1">
            Obituaries
          </Link>
        </Typography>

        <RightSide>
          <SearchIcon onClick={handleClickSearchIcon} />
        </RightSide>
      </Container>
    </>
  );
};

export default SubNav;
