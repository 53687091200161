import { setStorage } from "@/helpers/storage";
import { StepLabel } from "@mui/material";
import camelCase from "lodash/camelCase";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { Container, Dash, Step, StepIcon, Stepper } from "./Stepper.styled";

interface IProps {
  steps: string[];
  enabledSteps: string[];
  onClickCallback?: (label: string | null) => void;
}

const StepperComponent: React.FC<IProps> = ({
  steps,
  enabledSteps,
  onClickCallback
}) => {
  const { query } = useRouter();
  const { character, showMe } = query;
  const activeChar = character || "";

  useEffect(() => {
    setStorage("showMe", `${camelCase(String(showMe))}${activeChar}`);
  }, [activeChar, showMe]);

  return (
    <Container>
      <Stepper
        alternativeLabel
        classes={{ root: "paper" }}
        connector={<Dash classes={{ line: "line" }} />}
      >
        {steps.map(label => (
          <Step
            key={label}
            active={activeChar === label}
            classes={{
              horizontal: "horizontal-step"
            }}
            disabled={!enabledSteps.includes(label)}
          >
            <StepLabel
              onClick={() => {
                if (enabledSteps.includes(label) && onClickCallback) {
                  if (activeChar === label) {
                    onClickCallback(null);
                  } else {
                    onClickCallback(label);
                  }
                }
              }}
              StepIconComponent={() => (
                <StepIcon
                  isActive={activeChar === label}
                  isDisabled={!enabledSteps.includes(label)}
                >
                  {label}
                </StepIcon>
              )}
            />
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default StepperComponent;
