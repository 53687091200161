import styled from "@/components/contexts/ThemeProvider/styled";

export const CardContainer = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    width: 750
  },

  [theme.breakpoints.up("md")]: {
    width: 900
  }
}));
