import styled from "@/components/contexts/ThemeProvider/styled";
import MuiSearchIcon from "@mui/icons-material/Search";
import { Theme } from "@mui/system";

export const Container = styled("div")(({ theme }: { theme: Theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(3)
  },

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",

    "& > *": {
      marginRight: theme.spacing(4.5)
    }
  }
}));

export const RightSide = styled("div")(({ theme }: { theme: Theme }) => ({
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.up("md")]: {
    marginTop: 0,
    alignSelf: "end"
  }
}));

export const SearchIcon = styled(MuiSearchIcon)(
  ({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.up("sm")]: {
      visibility: "hidden"
    }
  })
);
