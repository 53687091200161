// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type IServiceDurationFragment = (
  { __typename?: 'service_duration' }
  & Pick<Types.IService_Duration, 'id' | 'service_id' | 'begin' | 'end' | 'type' | 'begin_offset' | 'end_offset'>
);

export const ServiceDurationFragmentDoc = gql`
    fragment ServiceDuration on service_duration {
  id
  service_id
  begin
  end
  type
  begin_offset
  end_offset
}
    `;