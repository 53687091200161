import CustomDatepicker from "@/components/atoms/Datepicker/Datepicker";
import { FiltersContext } from "@/components/contexts/FiltersProvider/FiltersProvider";
import { IFilters } from "@/components/contexts/FiltersProvider/FiltersProvider.types";
import { Select } from "@/components/molecules";
import FilterInput from "@/components/organisms/FilterInput/FilterInput";
import { useGetDisplayCityQuery } from "@/graphql/queries/__generated__/getDisplayCity.types";
import { useGetDisplayFuneralHomeQuery } from "@/graphql/queries/__generated__/getDisplayFuneralHome.types";
import { useGetDisplayStateQuery } from "@/graphql/queries/__generated__/getDisplayState.types";
import { DateRange, DateRangeOptions, getDatesInRange } from "@/helpers/dates";
import { INDEX_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { Button } from "@gannettdigital/community-hub-components";
import { Box, Grid, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ActionsContainer,
  AdvancedSearchContainer,
  CloseIcon,
  Drawer,
  LinkButton
} from "./FiltersDrawer.styled";

type IAdvancesSearchFilters = Pick<IFilters, 'after' | 'before' | 'city' | 'dateRange' | 'state' | 'funeralHome'>

const defaultAdvancedSearchValues: IAdvancesSearchFilters = {
  after: null,
  before: null,
  city: '',
  dateRange: '',
  state: '',
  funeralHome: ''
};

const FilterDrawer = () => {
  const router = useRouter();
  const { applyFiltersToRoute, goToPage } = useContext(FiltersContext);

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<IAdvancesSearchFilters>({
    ...defaultAdvancedSearchValues,
    ...router.query
  });

  const hookFormMethods = useForm<IAdvancesSearchFilters>({
    defaultValues: filters
  });

  const { handleSubmit, setValue, reset, getValues, formState: {errors} } = hookFormMethods;

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    reset(filters);
  }, [filters, reset]);

  const handleTrack = (event: string) => {
    trackEvent({
      action: `filter|${event}`,
      label: window.location.href,
      pageType: INDEX_PAGE_TYPE,
    });
  };

  const handleChangeDateRange = (value: unknown) => {
    if (
      typeof value === "string" &&
      value !== "" &&
      value !== DateRange.Custom
    ) {
      const { after, before } = getDatesInRange(value);
      setValue("after", after);
      setValue("before", before);
    }
  };

  const handleChangeDatePicker = useCallback(() => {
    const values = hookFormMethods.getValues();
    setValue("dateRange", DateRange.Custom);
    if (values.after) setValue("after", values.after);
    if (values.before) setValue("before", values.before);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = (isOpen: boolean) => {
    handleTrack(`drawer|${isOpen === true ? "open" : "close"}`);
    setOpen(isOpen);
  };

  const onSubmit = () => {
    const newFilters = getValues();

    const list = [];
    if (newFilters.dateRange) list.push(newFilters.dateRange);
    if (newFilters.city) list.push("city");
    if (newFilters.state) list.push("state");
    if (newFilters.funeralHome) list.push("funeralhome");
    if (list.length > 0)
      handleTrack(`fields|${list.join("|").toLocaleLowerCase()}`);

    setFilters(newFilters);
    applyFiltersToRoute(newFilters);
    setOpen(false);
  };

  return (
    <div>
      <LinkButton name="Advanced Search" onClick={() => toggleDrawer(true)} />
      <Drawer
        anchor="top"
        open={open}
        onClose={() => toggleDrawer(false)}
        classes={{
          paper: "drawer",
        }}
      >
        <AdvancedSearchContainer container>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Typography variant="h2">Advanced Search</Typography>
              </Grid>
              <Grid item xs={6}>
                <CloseIcon
                  onClick={() => {
                    toggleDrawer(false);
                  }}
                />
              </Grid>
            </Grid>
            <FormProvider {...hookFormMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} md={4}>
                    <Box mb={{ xs: 3, sm: 3 }}>
                      <Typography variant="h3">By Date</Typography>
                      <Box mb={4}>
                        <Select
                          name="dateRange"
                          options={DateRangeOptions}
                          label="Date Range"
                          onChangeCallback={handleChangeDateRange}
                        />
                      </Box>
                      <Box mb={4}>
                        <CustomDatepicker
                          name="after"
                          label="from"
                          labelAsPlaceholder
                          disableFuture
                          onChangeCallback={handleChangeDatePicker}
                        />
                      </Box>
                      <Box>
                        <CustomDatepicker
                          name="before"
                          label="to"
                          labelAsPlaceholder
                          disableFuture
                          onChangeCallback={handleChangeDatePicker}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box mb={{ xs: 3, sm: 3 }}>
                      <Typography variant="h3">By Location</Typography>
                      <Box mb={2.4}>
                        <FilterInput
                          name="city"
                          placeholder="City"
                          query={useGetDisplayCityQuery}
                        />
                      </Box>
                      <Box>
                        <FilterInput
                          minInputLength={1}
                          name="state"
                          placeholder="State"
                          query={useGetDisplayStateQuery}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h3">By Funeral Home</Typography>
                    <Box>
                      <FilterInput
                        name="funeralHome"
                        placeholder="Funeral Home"
                        query={useGetDisplayFuneralHomeQuery}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <ActionsContainer container justifyContent="center" spacing={3}>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!!(errors.before || errors.after)}
                    >
                      Apply Filters
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="reset"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        reset(defaultAdvancedSearchValues);
                        goToPage(1);
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Grid>
                </ActionsContainer>
              </form>
            </FormProvider>
          </Grid>
        </AdvancedSearchContainer>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
