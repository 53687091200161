import styled from "@/components/contexts/ThemeProvider/styled";
import { Link as MuiLink } from "@mui/material";

export const Link = styled(MuiLink)(({ theme }) => ({
  display: "inline-block",
  maxWidth: 300,
  textAlign: "left",
  "& p": {
    paddingBottom: theme.spacing(0.5)
  },
  "& h3": {
    paddingBottom: theme.spacing(1)
  }
}));

export const MoreContainer = styled("div")(({ theme }) => ({
  fontSize: 16,
  textAlign: "right",
  "& img": {
    height: "12px",
    marginLeft: theme.spacing(0.5),
    width: "18px"
  }
}));

export const BannerImg = styled("img")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  width: "100%"
}));
