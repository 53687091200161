/* eslint-disable no-useless-escape */
import { IService_Duration_Type_Enum } from "@/graphql/__generated__/graphql.types";
import { IServiceFragment } from "@/graphql/fragments/__generated__/service.types";
import { IServiceDurationFragment } from "@/graphql/fragments/__generated__/serviceDuration.types";
import isBefore from "date-fns/isBefore";
import * as yup from "yup";

export interface IExtendedServiceDurationFragment
  extends IServiceDurationFragment {
  date: Date | null;
}

export interface IExtendedServiceFragment
  extends Omit<IServiceFragment, "service_durations"> {
  service_durations: IExtendedServiceDurationFragment[];
}

export interface IServicesForm {
  services: IExtendedServiceFragment[];
}

export type IServiceToDelete = Pick<IServiceFragment, "id" | "__typename"> & {
  index: number;
};

interface IYupDurationContext {
  options: {
    parent: IExtendedServiceDurationFragment;
  };
}

export const defaultService: IExtendedServiceFragment = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  external_uri: "",
  external_uri_description: "",
  id: "",
  latitude: 0,
  longitude: 0,
  name: "",
  notes: "",
  obituary_id: "",
  phonenumber: "",
  state: "",
  title: "",
  zip: "",
  service_durations: [
    {
      id: "",
      service_id: "",
      date: null,
      begin: null,
      end: null,
      type: IService_Duration_Type_Enum.AllDay,
      begin_offset: null,
      end_offset: null
    }
  ]
};

// URL regex that starts with HTTP or HTTPS
// eslint-disable-next-line no-useless-escape
const urlRegex =
  /^$|https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

const messageRequired = "The field is required!";

export const validationSchema = yup.object().shape({
  services: yup.array().of(
    yup.object().shape({
      address1: yup.string().required(messageRequired),
      address2: yup.string(),
      city: yup.string().required(messageRequired),
      country: yup.string().required(messageRequired),
      external_uri: yup
        .string()
        .matches(urlRegex, "Valid URL format: https://example.com")
        .nullable(),
      name: yup.string().required(messageRequired),
      notes: yup.string(),
      state: yup.string().required(messageRequired),
      title: yup.string().required(messageRequired),
      zip: yup.string().required(messageRequired),
      service_durations: yup.array().of(
        yup.object().shape({
          date: yup
            .mixed()
            .test(
              "Empty date, but begin time is selected",
              "Clear times or pick a date!",
              function (value) {
                if (
                  !value &&
                  ((this as yup.TestContext & IYupDurationContext).options
                    .parent.begin ||
                    (this as yup.TestContext & IYupDurationContext).options
                      .parent.end)
                ) {
                  return false;
                }
                return true;
              }
            )
            .nullable(),
          begin: yup
            .date()
            .test(
              "Empty begin time, but end time is selected",
              "Clear end time or pick a start time!",
              function (value) {
                if (
                  !value &&
                  (this as yup.TestContext & IYupDurationContext).options.parent
                    .end
                ) {
                  return false;
                }
                return true;
              }
            )
            .nullable(),
          end: yup
            .date()
            .test(
              "End time is before start time",
              "End time cannot be before start time!",
              function (value) {
                if (
                  isBefore(
                    value,
                    (this as yup.TestContext & IYupDurationContext).options
                      .parent.begin
                  )
                ) {
                  return false;
                }
                return true;
              }
            )
            .nullable(),
          begin_offset: yup.number().test(
            'If begin time is selected, timezone is required',
            'The field is required!',
            function (value) {
              if (
                !value &&
                (this as yup.TestContext & IYupDurationContext).options.parent
                  .begin
              ) {
                return false;
              }
              return true;
            }
            )
            .nullable(),
        })
      )
    })
  )
});
