import type { IManageListObituaryFragment } from "@/graphql/fragments/__generated__/manageListObituary.types";
import { getBasicDate } from "@/helpers/dates";
import { getFullName, obituaryTagsToPublishers } from "@/helpers/obituary";
import { formatImageURL } from "@/helpers/urls";
import { ManageCard, ManageCardProps } from "@gannettdigital/community-hub-components";

interface ManageCardItemProps extends IManageListObituaryFragment {
  hasMemories: boolean;
}

const ManageCardItem = ({
  hasMemories,
  ...obitManageListFragment
}: ManageCardItemProps) => {
  const manageCardProps = obitFragmentToManageCardProps(
    obitManageListFragment,
    hasMemories
  );

  return <ManageCard {...manageCardProps} />;
};

export default ManageCardItem;

/**
 * Convert the GraphQL response to props required for <ManageCard />.
 */
const obitFragmentToManageCardProps = (
  o: IManageListObituaryFragment,
  hasMemories: boolean
): ManageCardProps => {
  const publications = obituaryTagsToPublishers(o.obituary_tags);

  return {
    id: o.id,
    name: getFullName(o),
    photoUrl: o.images.length > 0 ? formatImageURL(o.images[0].uri) : undefined,
    viewUrl: `/obituaries/${o.slug}`,
    // Can be added once the edit obituary feature is completed
    // editUrl: `/obituaries/edit-obituary/${o.slug}`,
    publications: publications.length > 0 ? publications : ["N/A"],
    postedOnlineDate: displayDate(o.historical_obituary?.external_published_at),
    printedDate: displayDate(o.historical_obituary?.external_ran_from),
    status: o.status,
    features: {
      "Life story": true,
      "Main photo": o.images.length > 0,
      Services: o.services.length > 0,
      "Memory board": hasMemories,
    }
  };
};

/**
 * Display date string
 * @return string | "N/A"
 */
const displayDate = (dateStr?: string) =>
  dateStr ? getBasicDate(dateStr) : "N/A";
