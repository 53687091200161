import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { serifFontFamily } from "@gannettdigital/community-hub-components";
import MuiSearchIcon from "@mui/icons-material/Search";
import { TextField as MuiTextField } from "@mui/material";

export const Container = styled("div")({
  width: "100%"
});

export const TextField = styled(MuiTextField)({
  color: Colors.gray2,
  fontFamily: serifFontFamily,
  fontSize: 18,

  "& .MuiInputLabel-root": {
    fontFamily: serifFontFamily
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderWidth: "1px",
    color: "inherit"
  },
  "& .MuiOutlinedInput-input": {
    fontSize: 18
  },
  "& .MuiInputLabel-outlined": {
    color: Colors.gray2,
    fontSize: 18
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    backgroundColor: Colors.white,
    paddingRight: 8
  }
});

export const SearchIcon = styled(MuiSearchIcon)({
  cursor: "pointer",
  width: 24,
  height: 24
});

export const Divider = styled("div")({
  borderTop: `1px solid ${Colors.gray5}`
});

export const ResultsContainerParent = styled("div")({
  position: "relative"
});

export const ResultsContainer = styled("div")(({ theme }) => ({
  backgroundColor: Colors.white,
  border: `1px solid ${Colors.gray5}`,
  boxShadow: "0px 7px 11px 0px rgba(0,0,0,0.4)",
  overflowY: "auto",
  maxHeight: "180px",
  position: "absolute",
  width: "100%",
  top: "-3px",
  padding: theme.spacing(0, 2),
  zIndex: 2,
  "& :first-of-type": {
    borderTop: "none"
  }
}));
