// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IDeleteServiceMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type IDeleteServiceMutation = (
  { __typename?: 'mutation_root' }
  & { delete_service_duration?: Types.Maybe<(
    { __typename?: 'service_duration_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service_duration' }
      & Pick<Types.IService_Duration, 'id'>
    )> }
  )>, delete_service_by_pk?: Types.Maybe<(
    { __typename?: 'service' }
    & Pick<Types.IService, 'id'>
  )> }
);


export const DeleteServiceDocument = gql`
    mutation DeleteService($id: uuid!) {
  delete_service_duration(where: {service_id: {_eq: $id}}) {
    returning {
      id
    }
  }
  delete_service_by_pk(id: $id) {
    id
  }
}
    `;
export type IDeleteServiceMutationFn = Apollo.MutationFunction<IDeleteServiceMutation, IDeleteServiceMutationVariables>;

/**
 * __useDeleteServiceMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMutation, { data, loading, error }] = useDeleteServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteServiceMutation, IDeleteServiceMutationVariables>) {
        return Apollo.useMutation<IDeleteServiceMutation, IDeleteServiceMutationVariables>(DeleteServiceDocument, baseOptions);
      }
export type DeleteServiceMutationHookResult = ReturnType<typeof useDeleteServiceMutation>;
export type DeleteServiceMutationResult = Apollo.MutationResult<IDeleteServiceMutation>;
export type DeleteServiceMutationOptions = Apollo.BaseMutationOptions<IDeleteServiceMutation, IDeleteServiceMutationVariables>;