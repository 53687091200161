// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IServiceDurationFragment } from '../../fragments/__generated__/serviceDuration.types';
import { IServiceFragment } from '../../fragments/__generated__/service.types';
import { gql } from '@apollo/client';
import { ServiceDurationFragmentDoc } from '../../fragments/__generated__/serviceDuration.types';
import { ServiceFragmentDoc } from '../../fragments/__generated__/service.types';
import * as Apollo from '@apollo/client';
export type IUpsertServiceMutationVariables = Types.Exact<{
  services: Array<Types.IService_Insert_Input> | Types.IService_Insert_Input;
  durations: Array<Types.IService_Duration_Insert_Input> | Types.IService_Duration_Insert_Input;
}>;


export type IUpsertServiceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_service_duration?: Types.Maybe<(
    { __typename?: 'service_duration_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service_duration' }
      & IServiceDurationFragment
    )> }
  )>, insert_service?: Types.Maybe<(
    { __typename?: 'service_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service' }
      & IServiceFragment
    )> }
  )> }
);


export const UpsertServiceDocument = gql`
    mutation UpsertService($services: [service_insert_input!]!, $durations: [service_duration_insert_input!]!) {
  insert_service_duration(
    objects: $durations
    on_conflict: {constraint: service_duration_pkey, update_columns: [begin, end, type, begin_offset, end_offset]}
  ) {
    returning {
      ...ServiceDuration
    }
  }
  insert_service(
    objects: $services
    on_conflict: {constraint: service_pkey, update_columns: [address1, address2, city, country, external_uri, external_uri_description, latitude, longitude, name, notes, phonenumber, state, title, zip]}
  ) {
    returning {
      ...Service
    }
  }
}
    ${ServiceDurationFragmentDoc}
${ServiceFragmentDoc}`;
export type IUpsertServiceMutationFn = Apollo.MutationFunction<IUpsertServiceMutation, IUpsertServiceMutationVariables>;

/**
 * __useUpsertServiceMutation__
 *
 * To run a mutation, you first call `useUpsertServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertServiceMutation, { data, loading, error }] = useUpsertServiceMutation({
 *   variables: {
 *      services: // value for 'services'
 *      durations: // value for 'durations'
 *   },
 * });
 */
export function useUpsertServiceMutation(baseOptions?: Apollo.MutationHookOptions<IUpsertServiceMutation, IUpsertServiceMutationVariables>) {
        return Apollo.useMutation<IUpsertServiceMutation, IUpsertServiceMutationVariables>(UpsertServiceDocument, baseOptions);
      }
export type UpsertServiceMutationHookResult = ReturnType<typeof useUpsertServiceMutation>;
export type UpsertServiceMutationResult = Apollo.MutationResult<IUpsertServiceMutation>;
export type UpsertServiceMutationOptions = Apollo.BaseMutationOptions<IUpsertServiceMutation, IUpsertServiceMutationVariables>;