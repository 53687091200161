import styled from "@/components/contexts/ThemeProvider/styled";

export const ProcessContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  justifyContent: "center",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2.5),
  background:
    "linear-gradient(273.74deg, #EAF6FF -29.34%, rgba(234, 246, 255, 0.26) 100%)",

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(0),
    flexDirection: "row-reverse",
    gap: 100,
    padding: theme.spacing(7.5)
  },

  [theme.breakpoints.up("md")]: {
    gap: 150
  },

  [theme.breakpoints.up("xl")]: {
    padding: theme.spacing(7.5, 20, 7.5)
  }
}));

export const Image = styled("img")(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  width: 228,

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(4),
    width: 300
  }
}));

export const LeftColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

export const TextContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: 600
  }
}));

export const AccordionContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: 525
  }
}));
