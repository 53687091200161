// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IHistoricalObituaryFragment } from './historicalObituary.types';
import { ILifeStoryFragment } from './lifeStory.types';
import { IObituaryTagFragment } from './obituaryTag.types';
import { gql } from '@apollo/client';
import { HistoricalObituaryFragmentDoc } from './historicalObituary.types';
import { LifeStoryFragmentDoc } from './lifeStory.types';
import { ObituaryTagFragmentDoc } from './obituaryTag.types';
export type IManageListObituaryFragment = (
  { __typename?: 'obituary' }
  & Pick<Types.IObituary, 'id' | 'slug' | 'status'>
  & { historical_obituary?: Types.Maybe<(
    { __typename?: 'historical_obituary' }
    & IHistoricalObituaryFragment
  )>, images: Array<(
    { __typename?: 'image' }
    & Pick<Types.IImage, 'uri'>
  )>, life_stories: Array<(
    { __typename?: 'life_story' }
    & ILifeStoryFragment
  )>, obituary_tags: Array<(
    { __typename?: 'obituary_tag' }
    & IObituaryTagFragment
  )>, services: Array<(
    { __typename?: 'service' }
    & Pick<Types.IService, 'id'>
  )> }
);

export const ManageListObituaryFragmentDoc = gql`
    fragment ManageListObituary on obituary {
  id
  slug
  status
  historical_obituary {
    ...HistoricalObituary
  }
  images(limit: 1) {
    uri
  }
  life_stories(order_by: {created_at: desc}, limit: 1) {
    ...LifeStory
  }
  obituary_tags {
    ...ObituaryTag
  }
  services(limit: 1) {
    id
  }
}
    ${HistoricalObituaryFragmentDoc}
${LifeStoryFragmentDoc}
${ObituaryTagFragmentDoc}`;