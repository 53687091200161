import { INDEX_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import Link from "@mui/material/Link";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const FeatherstitchCTA = () => {
  const { trackEvent } = useAnalytics();
  return (
    <Link
      onClick={() =>
        trackEvent({
          action: "cta|featherstitch",
          label: "https://www.featherstitch.com",
          pageType: INDEX_PAGE_TYPE,
        })
      }
      href="https://www.featherstitch.com"
      target="_blank"
      rel="noopener"
    >
      <picture>
        <img
          alt="featherstitch banner"
          src={`${publicRuntimeConfig.assetsPrefix}/static/images/cta/featherstitchBannerMobile.svg`}
        />
      </picture>
    </Link>
  );
};

export default FeatherstitchCTA;
