import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import { serifFontFamily } from "@gannettdigital/community-hub-components";
import {
    FormControl as MuiFormControl,
    MenuItem as MuiMenuItem
} from "@mui/material";

export const FormControl = styled(MuiFormControl)({
  background: Colors.white,
  "& .MuiSelect-select": {
    backgroundColor: "transparent !important",
    fontSize: 18
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderWidth: "1px"
  },
  "& .MuiMenuItem-root": {
    fontSize: 18
  },
  "& label": {
    backgroundColor: Colors.white,
    color: `${Colors.gray3} !important`,
    fontFamily: serifFontFamily,
    fontSize: 18,
    padding: "0 8px 0 1px",
    margin: "-1px 0 0 0"
  },
  "& input": {
    fontSize: 18
  }
});

export const MenuItem = styled(MuiMenuItem)({
  fontFamily: `${serifFontFamily} !important`,
  fontSize: "14px !important"
});
