import { LinkButton as CustomLinkButton } from "@/components/atoms";
import styled from "@/components/contexts/ThemeProvider/styled";
import { unifySansBoldFontFamily } from "@gannettdigital/community-hub-components";
import MuiCloseIcon from "@mui/icons-material/Close";
import { Drawer as MuiDrawer, Grid } from "@mui/material";

export const LinkButton = styled(CustomLinkButton)({
  padding: "12px 12px 12px 0"
});

export const AdvancedSearchContainer = styled(Grid)(({ theme }) => ({
  margin: "0 auto",
  maxWidth: "1190px",
  padding: theme.spacing(3, 3, 4),
  position: "relative",
  "& h2": {
    fontFamily: unifySansBoldFontFamily,
    fontSize: 20,
    marginBottom: theme.spacing(3)
  },
  "& h3": {
    fontFamily: unifySansBoldFontFamily,
    fontSize: 18,
    marginBottom: theme.spacing(3)
  }
}));

export const CloseIcon = styled(MuiCloseIcon)(({ theme }) => ({
  margin: theme.spacing(3),
  position: "absolute",
  right: 0,
  top: 0
}));

export const ActionsContainer = styled(Grid)(({ theme }) => ({
  marginTop: `${theme.spacing(3)}!important`,
  "& button": {
    minWidth: "138px"
  },
  "& .MuiGrid-item": {
    paddingTop: 0
  }
}));

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  "& .drawer": {
    [theme.breakpoints.down("sm")]: {
      height: "100%"
    }
  }
}));
