import { IHistoricalObituaryEventFragment } from "@/graphql/fragments/__generated__/historicalObituaryEvent.types";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { getFullName } from "@/helpers/obituary";
import Head from "next/head";
import React from "react";

// Full list is available at https://schema.org/docs/full.html
// Test using https://search.google.com/structured-data/testing-tool

export interface PostalAddress {
  "@type": "PostalAddress";
  addressCountry: "USA";
  addressRegion?: string | null;
  addressLocality?: string | null;
  doorTime?: string;
  location?: string;
}
export interface Place {
  "@type": "Place";
  name?: string;
  address: PostalAddress;
}
export interface Person {
  "@context": string;
  "@type": "Person";
  name: string;
  image?: string;
  birthDate?: string | null;
  deathDate?: string | null;
  deathPlace?: Place;
}

interface IObitProps {
  obituary: IObituaryFragment;
  profilePhoto?: string;
}

export const personJsonLD = ({ obituary, profilePhoto }: IObitProps) => {
  const person: Person = {
    "@context": "https://schema.org/",
    "@type": "Person",
    name: getFullName(obituary),
    image: profilePhoto,
    birthDate: obituary.historical_obituary?.date_born,
    deathDate: obituary.historical_obituary?.date_deceased
  };

  if (
    obituary.historical_obituary?.state ||
    obituary.historical_obituary?.city
  ) {
    person.deathPlace = {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressCountry: "USA",
        addressRegion: obituary.historical_obituary?.state,
        addressLocality: obituary.historical_obituary?.city
      }
    };
  }

  return JSON.stringify(person) as string;
};

interface IServiceProps {
  fullName?: string;
  event: IHistoricalObituaryEventFragment;
}

export interface Event {
  "@context": "https://schema.org/";
  "@type": "Event";
  name: string;
  startDate: any;
  endDate: any;
  location?: Place;
}

export const eventJsonLD = ({ fullName, event }: IServiceProps): string => {
  const ldEvent: Event = {
    "@context": "https://schema.org/",
    "@type": "Event",
    name: event.type ? `${event.type} of ${fullName}` : fullName || "",
    startDate: event.date,
    endDate: event.date
  };

  if (event.location) {
    ldEvent.location = {
      "@type": "Place",
      name: event.location || "",
      address: {
        "@type": "PostalAddress",
        addressCountry: "USA",
        doorTime: event.time,
        location: event.location
      }
    };
  }
  return JSON.stringify(ldEvent);
};

const ObitJsonLD: React.FC<IObitProps> = ({
  obituary,
  profilePhoto = ""
}): JSX.Element => {
  const fullName = String(getFullName(obituary));
  const events = obituary.historical_obituary?.historical_obituary_events;

  return (
    <Head>
      <>
        <script
          key="ld+json"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: personJsonLD({ obituary, profilePhoto })
          }}
        />
        {events &&
          events.length > 0 &&
          events.map((event, index) => (
            <script
              key={`${event.type}_${index}`}
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: eventJsonLD({ fullName, event })
              }}
            />
          ))}
      </>
    </Head>
  );
};

export default ObitJsonLD;
