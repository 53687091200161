// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IObitMemoryCheckFragment } from '../../fragments/__generated__/obitMemoryCheck.types';
import { gql } from '@apollo/client';
import { ObitMemoryCheckFragmentDoc } from '../../fragments/__generated__/obitMemoryCheck.types';
import * as Apollo from '@apollo/client';
export type ICheckObitsHaveMemoriesQueryVariables = Types.Exact<{
  obit_slugs?: Types.Maybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type ICheckObitsHaveMemoriesQuery = (
  { __typename?: 'query_root' }
  & { memories: Array<(
    { __typename?: 'memories' }
    & IObitMemoryCheckFragment
  )> }
);


export const CheckObitsHaveMemoriesDocument = gql`
    query CheckObitsHaveMemories($obit_slugs: [String!]) {
  memories(where: {obit: {slug: {_in: $obit_slugs}}}) {
    ...ObitMemoryCheck
  }
}
    ${ObitMemoryCheckFragmentDoc}`;

/**
 * __useCheckObitsHaveMemoriesQuery__
 *
 * To run a query within a React component, call `useCheckObitsHaveMemoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckObitsHaveMemoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckObitsHaveMemoriesQuery({
 *   variables: {
 *      obit_slugs: // value for 'obit_slugs'
 *   },
 * });
 */
export function useCheckObitsHaveMemoriesQuery(baseOptions?: Apollo.QueryHookOptions<ICheckObitsHaveMemoriesQuery, ICheckObitsHaveMemoriesQueryVariables>) {
        return Apollo.useQuery<ICheckObitsHaveMemoriesQuery, ICheckObitsHaveMemoriesQueryVariables>(CheckObitsHaveMemoriesDocument, baseOptions);
      }
export function useCheckObitsHaveMemoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICheckObitsHaveMemoriesQuery, ICheckObitsHaveMemoriesQueryVariables>) {
          return Apollo.useLazyQuery<ICheckObitsHaveMemoriesQuery, ICheckObitsHaveMemoriesQueryVariables>(CheckObitsHaveMemoriesDocument, baseOptions);
        }
export type CheckObitsHaveMemoriesQueryHookResult = ReturnType<typeof useCheckObitsHaveMemoriesQuery>;
export type CheckObitsHaveMemoriesLazyQueryHookResult = ReturnType<typeof useCheckObitsHaveMemoriesLazyQuery>;
export type CheckObitsHaveMemoriesQueryResult = Apollo.QueryResult<ICheckObitsHaveMemoriesQuery, ICheckObitsHaveMemoriesQueryVariables>;