// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IServiceFragment } from '../../fragments/__generated__/service.types';
import { gql } from '@apollo/client';
import { ServiceFragmentDoc } from '../../fragments/__generated__/service.types';
import * as Apollo from '@apollo/client';
export type IGetServicesByObitSlugQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type IGetServicesByObitSlugQuery = (
  { __typename?: 'query_root' }
  & { service: Array<(
    { __typename?: 'service' }
    & IServiceFragment
  )>, obituary_by_pk?: Types.Maybe<(
    { __typename?: 'obituary' }
    & Pick<Types.IObituary, 'is_owner'>
  )> }
);


export const GetServicesByObitSlugDocument = gql`
    query GetServicesByObitSlug($id: uuid!) {
  service(where: {obituary: {id: {_eq: $id}}}) {
    ...Service
  }
  obituary_by_pk(id: $id) {
    is_owner
  }
}
    ${ServiceFragmentDoc}`;

/**
 * __useGetServicesByObitSlugQuery__
 *
 * To run a query within a React component, call `useGetServicesByObitSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesByObitSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesByObitSlugQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServicesByObitSlugQuery(baseOptions: Apollo.QueryHookOptions<IGetServicesByObitSlugQuery, IGetServicesByObitSlugQueryVariables>) {
        return Apollo.useQuery<IGetServicesByObitSlugQuery, IGetServicesByObitSlugQueryVariables>(GetServicesByObitSlugDocument, baseOptions);
      }
export function useGetServicesByObitSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetServicesByObitSlugQuery, IGetServicesByObitSlugQueryVariables>) {
          return Apollo.useLazyQuery<IGetServicesByObitSlugQuery, IGetServicesByObitSlugQueryVariables>(GetServicesByObitSlugDocument, baseOptions);
        }
export type GetServicesByObitSlugQueryHookResult = ReturnType<typeof useGetServicesByObitSlugQuery>;
export type GetServicesByObitSlugLazyQueryHookResult = ReturnType<typeof useGetServicesByObitSlugLazyQuery>;
export type GetServicesByObitSlugQueryResult = Apollo.QueryResult<IGetServicesByObitSlugQuery, IGetServicesByObitSlugQueryVariables>;