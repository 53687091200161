/* eslint-disable @next/next/no-img-element */
import { IImageFragment } from "@/graphql/fragments/__generated__/image.types";
import { formatToCropParams } from "@/hooks/useImageFunctions";
import {
  CropMethod,
  CropParams, Drawer,
  DrawerContent,
  ImageEditor, ImagePreview, parseCropParams, Typography
} from "@gannettdigital/community-hub-components";
import "@gannettdigital/community-hub-components/dist/index.esm.css";
import { Divider } from "@mui/material";
import React from "react";

interface IProps {
  open: boolean;
  images: Partial<IImageFragment>[];
  selectedPhoto?: Partial<IImageFragment>;
  onClose: () => void;
  onCrop: (cropParams: CropParams) => void;
  onEditClick: (image: Partial<IImageFragment>) => void;
  onDeleteClick: (image: Partial<IImageFragment>) => void;
  loadingSubmit?: boolean;
}

const EditImageDrawer: React.FC<IProps> = ({
  open,
  images,
  selectedPhoto,
  onClose,
  onCrop,
  onEditClick,
  onDeleteClick,
  loadingSubmit
}) => {
  return (
    <Drawer open={open} hideBackdrop>
      <DrawerContent
        title="Edit Life Story"
        onClose={onClose}
        submitButtonProps={{
          disabled: loadingSubmit,
          onClick: () => {
            console.log("submit clicked");
          }
        }}
      >
        <div>
          <div style={{ padding: "30px" }}>
            <div>
              <Typography variant="h2">Edit Photo</Typography>
              <Typography variant="body2" mb={3}>
                Keep the default photo crop as is or adjust it using the + or -
                buttons. Drag the photo into the desired crop, or rotate it, as
                needed.
              </Typography>
            </div>
            {images.map((img, index) => (
              <React.Fragment key={img.uri ? + img.uri + index : index}>
                <div>
                  {selectedPhoto === img && img && img.uri ? (
                    <>
                      <ImageEditor
                        imgSrc={img.uri}
                        submitButtonProps={{
                          children: "Crop",
                          onClick: (img, crop, scale, rotate) => {
                            if (!img) {
                              return;
                            }

                            const cropParams = formatToCropParams(
                              img,
                              crop,
                              scale,
                              rotate
                            );

                            if (!cropParams) {
                              return;
                            }

                            onCrop(cropParams);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        height: "114px",
                        width: "114px"
                      }}
                    >
                      <ImagePreview
                        src={img.uri || ""}
                        cropParams={parseCropParams(img.params || "")}
                        cropMethod={CropMethod.localCanvas}
                        onEditClick={() => onEditClick(img)}
                        onDeleteClick={() => onDeleteClick(img)}
                      />
                    </div>
                  )}
                </div>
                <Divider style={{ margin: "20px 0px" }} />
              </React.Fragment>
            ))}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default EditImageDrawer;
