import {
  Button as CustomButton,
  styled,
  unifySansBoldFontFamily,
  unifySansRgFontFamily
} from "@gannettdigital/community-hub-components";
import MuiCloseIcon from "@mui/icons-material/Close";
import MuiSearchIcon from "@mui/icons-material/Search";
import { Theme } from "@mui/system";

export const Container = styled("div")(({ theme }: { theme: Theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(0, 3, 2, 0),

  "& > *": {
    marginBottom: theme.spacing(2)
  },

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    marginBottom: 0,

    "& > *": {
      marginBottom: 0
    }
  }
}));

export const SearchContainer = styled("div")(({ theme }: { theme: Theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.3),
  border: `1px solid ${theme.palette.all.gray5}`,
  borderRadius: 3,
  "&:focus-within": {
    borderColor: theme.palette.primary.main
  }
}));

export const SearchInput = styled("input")(({ theme }: { theme: Theme }) => ({
  width: "80%",
  border: 0,
  fontSize: 14,
  fontFamily: `${unifySansRgFontFamily} !important`,
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px" // 16px on mobile to disable auto zoom on ios
  },
  "&:focus": {
    outline: 0
  }
}));

export const SearchIcon = styled(MuiSearchIcon)(
  ({ theme }: { theme: Theme }) => ({
    visibility: "hidden",

    [theme.breakpoints.up("sm")]: {
      visibility: "visible",
      cursor: "pointer"
    }
  })
);

export const CloseIcon = styled(MuiCloseIcon)(() => ({
  cursor: "pointer",
  width: 24,
  height: 24
}));

export const SearchResultsContainer = styled("div")({
  position: "absolute",
  width: "100%",
  bottom: 0,
  left: 0
});

export const SearchResultsWrapper = styled("div")(
  ({ theme }: { theme: Theme }) => ({
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.all.white,
    boxShadow: "0px 9px 15px 3px rgba(0,0,0,0.4)",
    padding: theme.spacing(0, 2),
    zIndex: 1,
    "& :first-of-type": {
      borderTop: "none"
    }
  })
);

export const ChildrenContainer = styled("div")(
  ({ theme }: { theme: Theme }) => ({
    marginBottom: theme.spacing(2),
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      marginBottom: 0
    }
  })
);

export const Divider = styled("div")(({ theme }) => ({
  borderTop: `2px solid ${theme.palette.all.gray5}`
}));

export const Button = styled(CustomButton)(({ theme }: { theme: Theme }) => ({
  fontFamily: unifySansBoldFontFamily,
  marginBottom: 0,
  minWidth: "auto",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3)
  }
}));
