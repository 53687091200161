import { ImageWithLinearGradient } from "@/components/molecules";
import { Container, Subtitle } from "./NoServicesView.styled";

interface IProps {
  children: React.ReactNode;
}

const NoServices: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <Container>
        <Subtitle>No service information is available at this time.</Subtitle>
        {children}
      </Container>
      <ImageWithLinearGradient assetName="woman-with-flowers.svg" />
    </>
  );
};

export default NoServices;
