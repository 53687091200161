// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetDisplayFuneralHomeQueryVariables = Types.Exact<{
  term?: Types.Maybe<Types.Scalars['String']>;
  id: Types.Scalars['uuid'];
}>;


export type IGetDisplayFuneralHomeQuery = (
  { __typename?: 'query_root' }
  & { display_obituary: Array<(
    { __typename?: 'display_obituary' }
    & { funeralHome: Types.IDisplay_Obituary['funeral_home'] }
  )> }
);


export const GetDisplayFuneralHomeDocument = gql`
    query getDisplayFuneralHome($term: String = null, $id: uuid!) {
  display_obituary(
    where: {display_obituary_publications: {publication_id: {_eq: $id}}, funeral_home: {_ilike: $term, _is_null: false, _gt: "1"}}
    distinct_on: [funeral_home]
    order_by: [{funeral_home: asc}]
  ) {
    funeralHome: funeral_home
  }
}
    `;

/**
 * __useGetDisplayFuneralHomeQuery__
 *
 * To run a query within a React component, call `useGetDisplayFuneralHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayFuneralHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayFuneralHomeQuery({
 *   variables: {
 *      term: // value for 'term'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDisplayFuneralHomeQuery(baseOptions: Apollo.QueryHookOptions<IGetDisplayFuneralHomeQuery, IGetDisplayFuneralHomeQueryVariables>) {
        return Apollo.useQuery<IGetDisplayFuneralHomeQuery, IGetDisplayFuneralHomeQueryVariables>(GetDisplayFuneralHomeDocument, baseOptions);
      }
export function useGetDisplayFuneralHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDisplayFuneralHomeQuery, IGetDisplayFuneralHomeQueryVariables>) {
          return Apollo.useLazyQuery<IGetDisplayFuneralHomeQuery, IGetDisplayFuneralHomeQueryVariables>(GetDisplayFuneralHomeDocument, baseOptions);
        }
export type GetDisplayFuneralHomeQueryHookResult = ReturnType<typeof useGetDisplayFuneralHomeQuery>;
export type GetDisplayFuneralHomeLazyQueryHookResult = ReturnType<typeof useGetDisplayFuneralHomeLazyQuery>;
export type GetDisplayFuneralHomeQueryResult = Apollo.QueryResult<IGetDisplayFuneralHomeQuery, IGetDisplayFuneralHomeQueryVariables>;