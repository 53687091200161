import { Link } from "@/components/atoms";
import { usePubInfo } from "@/hooks/useAppState";
import { Typography } from "@gannettdigital/community-hub-components";
import { Container } from "./ManageNoResults.styled";

const ManageNoResultsView = () => {
  const { email } = usePubInfo();

  return (
    <Container spacing={2}>
      <Typography variant="h3">No obituary orders found.</Typography>
      <Typography variant="body2">
        If you have any questions about your order(s) please contact us at{" "}
        <Link href={`mailto:${email}`} rel="noreferrer" target="_blank">
          {email}
        </Link>
        .
      </Typography>
    </Container>
  );
};

export default ManageNoResultsView;
