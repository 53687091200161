import { IFromSalesOrderFragment } from "@/graphql/fragments/__generated__/fromSalesOrder.types";
import * as yup from "yup";

export const FORM_DEFAULT_VALUES: IFromSalesOrderFragment = {
  id: "",
  obituary_id: "",
  contact_firstname: "",
  contact_lastname: "",
  billing_address: "",
  billing_city: "",
  billing_state: "",
  billing_zip: "",
  billing_phone: "",
  billing_email: "",
  verification_provider: "",
  verification_contact: "",
  verification_phone: "",
  have_accepted_terms_and_service: false,
  print_start: null,
  print_end: null
};

const messageRequired = "The field is required!";

export const validationSchema = yup.object().shape({
  contact_firstname: yup.string().required(messageRequired),
  contact_lastname: yup.string().required(messageRequired),
  billing_address: yup.string().required(messageRequired),
  billing_city: yup.string().required(messageRequired),
  billing_state: yup.string().required(messageRequired),
  billing_zip: yup.string().required(messageRequired),
  billing_phone: yup.string().required(messageRequired),
  billing_email: yup.string().required(messageRequired),
  verification_provider: yup.string().required(messageRequired),
  verification_contact: yup.string().required(messageRequired),
  verification_phone: yup.string().required(messageRequired),
  have_accepted_terms_and_service: yup.boolean().required(messageRequired)
});
