export const splitFullTerm = (fullTerm: string, query: string) => {
  if (query === "") {
    return [fullTerm, "", ""];
  }
  const i = fullTerm.toLowerCase().indexOf(query.toLowerCase());
  if (i === -1) {
    return [fullTerm, "", ""];
  }
  return [
    fullTerm.slice(0, i),
    fullTerm.slice(i, i + query.length),
    fullTerm.slice(i + query.length)
  ];
};
