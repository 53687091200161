import { IObituaryBannerDataFragment } from "@/graphql/fragments/__generated__/obituaryBannerData.types";
import { IServiceFragment } from "@/graphql/fragments/__generated__/service.types";
import { getLeadPhotoUrl } from "@/helpers/obituary";
import { DETAIL_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { ServiceCard } from "@gannettdigital/community-hub-components";
import getConfig from "next/config";
import { CardContainer } from "./ServicesLayout.styled";

interface IProps {
  services: IServiceFragment[];
  obit: IObituaryBannerDataFragment;
  children: React.ReactNode;
}

const { publicRuntimeConfig } = getConfig();

const ServicesLayout: React.FC<IProps> = ({ services, obit, children }) => {
  const { trackEvent } = useAnalytics();

  return (
    <>
      {children}
      {services.map(
        ({
          service_durations,
          external_uri,
          latitude,
          longitude,
          id,
          title,
          ...restProps
        }) => {
          const firstName =
            (obit.historical_obituary && obit.historical_obituary.first_name) ||
            "";
          const lastName =
            (obit.historical_obituary && obit.historical_obituary.last_name) ||
            "";
          const durationsProps =
            service_durations.length > 0
              ? {
                  begin: service_durations[0].begin,
                  end: service_durations[0].end,
                  durationType: service_durations[0].type,
                  offset: service_durations[0].begin_offset
                }
              : {};

          return (
            <CardContainer key={id}>
              <ServiceCard
                {...restProps}
                {...durationsProps}
                title={title}
                ftdData={{
                  firstName,
                  lastName,
                  image: getLeadPhotoUrl(obit.images, obit.image_orders) || "",
                  baseFtdUrl: publicRuntimeConfig.ftdV2BaseUrl,
                }}
                livestreamUrl={external_uri || undefined}
                mapProps={{
                  mapboxAccessToken: publicRuntimeConfig.mapboxAccessToken,
                  coordinates: [longitude, latitude],
                  showErrorView: latitude === 0 && longitude === 0,
                }}
                sendFlowersProps={{
                  onClick: () =>
                    trackEvent({
                      category: "services",
                      action: "link click",
                      label: `send-flowers-${title}-${id}`,
                      pageType: DETAIL_PAGE_TYPE,
                    }),
                }}
                calendarProps={{
                  onClick: () =>
                    trackEvent({
                      category: "services",
                      action: "link click",
                      pageType: DETAIL_PAGE_TYPE,
                      label: "add-calendar",
                    }),
                }}
              />
            </CardContainer>
          );
        }
      )}
    </>
  );
};

export default ServicesLayout;
