import styled from "@/components/contexts/ThemeProvider/styled";

export const EndContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  background:
    "linear-gradient(to right, rgba(253, 236, 221, 0.51) -15.97%, rgba(253, 236, 221, 0) 113.39%)",
  padding: theme.spacing(3),

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(7.5)
  },

  [theme.breakpoints.up("xl")]: {
    padding: theme.spacing(7.5, 20)
  }
}));
