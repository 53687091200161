import CTATitle from "@/components/common/CTATitle";
import { INDEX_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import { Box, Typography } from "@mui/material";
import getConfig from "next/config";
import { BannerImg, Link, MoreContainer } from "./FuneralPlanningCTA.styled";

const { publicRuntimeConfig } = getConfig();

const FuneralPlanningCTA = () => {
  const { trackEvent } = useAnalytics();
  return (
    <Link
      onClick={() =>
        trackEvent({
          action: "cta|funeral-planning",
          label: "https://www.featherstitch.com",
          pageType: INDEX_PAGE_TYPE,
        })
      }
      href="https://www.featherstitch.com"
      target="_blank"
      rel="noopener"
      underline="none"
    >
      <CTATitle title="Funeral Planning" compact />
      <BannerImg
        alt="Funeral planning made easy"
        src={`${publicRuntimeConfig.assetsPrefix}/static/images/cta/funeralPlanningCta.jpg`}
      />
      <Box pb={1}>
        <Typography variant="h3">Funeral planning made easy</Typography>
        <Typography variant="body1">
          Let Featherstitch guide you through the funeral planning process at no
          charge.
        </Typography>
        <Typography variant="body1">
          • Step-by-step, easy-to-follow guide to organizing and planning the
          event.
        </Typography>
        <Typography variant="body1">
          • Tips & advice to help you make decisions.
        </Typography>
      </Box>
      <MoreContainer>
        Learn More
        <picture>
          <img
            alt=""
            src={`${publicRuntimeConfig.assetsPrefix}/static/images/icon-arrow-right.svg`}
          />
        </picture>
      </MoreContainer>
    </Link>
  );
};

export default FuneralPlanningCTA;
