import { ManageObituariesLoadingStack } from "@/components/molecules";
import { ManageNoResults } from "@/components/organisms";
import { Typography } from "@gannettdigital/community-hub-components";
import ManageCardItem from "./ManageCardItem";
import { Container } from "./ManageObituariesList.styled";
import { useManageObituariesList } from "./useManageObituariesList";

const ManageObituariesList = () => {
  const { obitsArray, error, loading, memoriesArray } =
    useManageObituariesList();

  if (loading) {
    return <ManageObituariesLoadingStack />;
  }

  if (error) {
    return (
      <Typography variant="h3" textAlign="center">
        We&apos;re sorry. An error occurred when fetching your obituaries.
      </Typography>
    );
  }

  if (obitsArray.length === 0) {
    return <ManageNoResults />;
  }

  return (
    <Container>
      {obitsArray.map(obit => {
        const hasMemories = memoriesArray.some(m => m.obit?.slug === obit.slug);
        return (
          <ManageCardItem key={obit.id} hasMemories={hasMemories} {...obit} />
        );
      })}
    </Container>
  );
};

export default ManageObituariesList;
