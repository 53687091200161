// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetDisplaySearchQueryVariables = Types.Exact<{
  before?: Types.Maybe<Types.Scalars['timestamp']>;
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  search?: Types.Maybe<Types.Scalars['String']>;
  id: Types.Scalars['uuid'];
}>;


export type IGetDisplaySearchQuery = (
  { __typename?: 'query_root' }
  & { display_obituary: Array<(
    { __typename?: 'display_obituary' }
    & Pick<Types.IDisplay_Obituary, 'age' | 'body' | 'city' | 'full_name' | 'photo_urls' | 'source_published_at' | 'source_slug' | 'state'>
  )>, display_obituary_aggregate: (
    { __typename?: 'display_obituary_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'display_obituary_aggregate_fields' }
      & Pick<Types.IDisplay_Obituary_Aggregate_Fields, 'count'>
    )> }
  ) }
);


export const GetDisplaySearchDocument = gql`
    query getDisplaySearch($before: timestamp = null, $limit: Int!, $offset: Int!, $search: String = null, $id: uuid!) {
  display_obituary(
    limit: $limit
    offset: $offset
    where: {display_obituary_publications: {publication_id: {_eq: $id}}, full_name: {_ilike: $search}, source_published_at: {_lte: $before}}
    order_by: [{source_published_at: desc_nulls_last}, {source_slug: desc_nulls_last}]
  ) {
    age
    body
    city
    full_name
    photo_urls
    source_published_at
    source_slug
    state
  }
  display_obituary_aggregate(
    where: {display_obituary_publications: {publication_id: {_eq: $id}}, full_name: {_ilike: $search}, source_published_at: {_lte: $before}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetDisplaySearchQuery__
 *
 * To run a query within a React component, call `useGetDisplaySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplaySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplaySearchQuery({
 *   variables: {
 *      before: // value for 'before'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDisplaySearchQuery(baseOptions: Apollo.QueryHookOptions<IGetDisplaySearchQuery, IGetDisplaySearchQueryVariables>) {
        return Apollo.useQuery<IGetDisplaySearchQuery, IGetDisplaySearchQueryVariables>(GetDisplaySearchDocument, baseOptions);
      }
export function useGetDisplaySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDisplaySearchQuery, IGetDisplaySearchQueryVariables>) {
          return Apollo.useLazyQuery<IGetDisplaySearchQuery, IGetDisplaySearchQueryVariables>(GetDisplaySearchDocument, baseOptions);
        }
export type GetDisplaySearchQueryHookResult = ReturnType<typeof useGetDisplaySearchQuery>;
export type GetDisplaySearchLazyQueryHookResult = ReturnType<typeof useGetDisplaySearchLazyQuery>;
export type GetDisplaySearchQueryResult = Apollo.QueryResult<IGetDisplaySearchQuery, IGetDisplaySearchQueryVariables>;