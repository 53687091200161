// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { gql } from '@apollo/client';
export type ILifeStoryFragment = (
  { __typename?: 'life_story' }
  & Pick<Types.ILife_Story, 'id' | 'first_name' | 'middle_name' | 'last_name' | 'suffix' | 'birth' | 'passing' | 'story'>
);

export const LifeStoryFragmentDoc = gql`
    fragment LifeStory on life_story {
  id
  first_name
  middle_name
  last_name
  suffix
  birth
  passing
  story
}
    `;