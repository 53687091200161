// DO NOT MODIFY
// This file is autogenerated
// To update: run "yarn gql:codegen-operations"

/* eslint-disable */

import * as Types from '../../__generated__/graphql.types';

import { IServiceDurationFragment } from './serviceDuration.types';
import { gql } from '@apollo/client';
import { ServiceDurationFragmentDoc } from './serviceDuration.types';
export type IServiceFragment = (
  { __typename?: 'service' }
  & Pick<Types.IService, 'address1' | 'address2' | 'city' | 'country' | 'external_uri' | 'external_uri_description' | 'id' | 'latitude' | 'longitude' | 'name' | 'notes' | 'state' | 'title' | 'zip' | 'obituary_id' | 'phonenumber'>
  & { service_durations: Array<(
    { __typename?: 'service_duration' }
    & IServiceDurationFragment
  )> }
);

export const ServiceFragmentDoc = gql`
    fragment Service on service {
  address1
  address2
  city
  country
  external_uri
  external_uri_description
  id
  latitude
  longitude
  name
  notes
  state
  title
  zip
  obituary_id
  service_durations {
    ...ServiceDuration
  }
  phonenumber
}
    ${ServiceDurationFragmentDoc}`;