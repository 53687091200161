import { LinkButton, SearchTitle } from "@/components/atoms";
// TODO: move context outside of components folder
import { FiltersContext } from "@/components/contexts/FiltersProvider/FiltersProvider";
import styled from "@/components/contexts/ThemeProvider/styled";
import {
  DateRange,
  DateRangeOptions,
  formattedDateString
} from "@/helpers/dates";
import { Grid, GridProps } from "@mui/material";
import { useRouter } from "next/router";
import React, { useContext, useMemo } from "react";

interface ContainerProps extends GridProps {
  padding?: string | number;
}

const Container = styled((props: ContainerProps) => <Grid {...props} />, {
  shouldForwardProp: prop => prop !== "padding"
})(({ padding }) => ({
  "&.MuiGrid-item": {
    paddingTop: padding || 0,
    paddingBottom: padding || 0
  }
}));

interface IProps {
  numResults: number;
  padding: number;
}

const ResultsHeader: React.FC<IProps> = ({
  numResults,
  padding
}) => {
  const router = useRouter();
  const { applyFiltersToRoute } = useContext(FiltersContext)
  const { query } = router as any;
  const hasFilter = [
    query.dateRange,
    query.city,
    query.funeralHome,
    query.state
  ].some(Boolean);
  const publication = query.pubname || "";

  const PageHeaderComponent = () =>
    useMemo(() => {
      const results = `${numResults} Result${numResults === 1 ? "" : "s"} for`;
      const city = `${query.city ? query.city : ""}`;
      const fh = `${query.funeralHome ? query.funeralHome : ""}`;
      const state = `${query.state ? query.state : ""}`;
      const cityState = `${city}${city && state ? ", " : ""}${state}`;
      const link = <LinkButton onClick={() => applyFiltersToRoute({})} name="clear filter" />;

      if (query.search) {
        return (
          <Container container item padding={padding}>
            <SearchTitle title={`${results} "${query.search}"`}>
              {link}
            </SearchTitle>
          </Container>
        );
      }
      if (query.ids && query.pubname) {
        return (
          <Container container item padding={padding}>
            <SearchTitle title={`${results} "${publication}"`}>
              {link}
            </SearchTitle>
          </Container>
        );
      }
      if (hasFilter) {
        let display = "";
        const filterName = DateRangeOptions.reduce<string | unknown>(
          (_, currentValue) => {
            if (currentValue.value === query.dateRange) {
              if (currentValue.value !== DateRange.Custom) {
                display = currentValue.label;
              } else if (query.before && query.after) {
                display = `${formattedDateString(
                  query.after
                )} to ${formattedDateString(query.before)}`;
              } else if (query.after) {
                display = `${formattedDateString(query.after)} and later`;
              } else if (query.before) {
                display = `${formattedDateString(query.before)} and earlier`;
              }
            }
            return `${display}
          ${display.length && cityState.length ? " in " : ""}
          ${cityState}
          ${(display.length || cityState.length) && fh ? " at " : ""}
          ${fh}`;
          },
          []
        );
        return (
          <Container container item padding={padding}>
            <SearchTitle title={`${results} "${String(filterName).trim()}"`}>
              {link}
            </SearchTitle>
          </Container>
        );
      }
      return null;
    }, []);

  return <PageHeaderComponent />;
};

export default ResultsHeader;
