import { Container } from "@/components/molecules/ManageObituariesList/ManageObituariesList.styled";
import { LoadingManageCard } from "@gannettdigital/community-hub-components";

const ManageObituariesLoadingStack = () => {
  return (
    <Container>
      <LoadingManageCard />
      <LoadingManageCard />
    </Container>
  );
};

export default ManageObituariesLoadingStack;
