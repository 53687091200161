export interface IShowMe {
  datePostedOnline: string;
  byLocation: string;
  byFuneralHome: string;
}

export enum SHOW_ME_KEYS {
  datePostedOnline = "datePostedOnline",
  byLocation = "byLocation",
  byFuneralHome = "byFuneralHome"
}

export const SHOW_ME_VALUES: IShowMe = {
  [SHOW_ME_KEYS.datePostedOnline]: "Date posted online",
  [SHOW_ME_KEYS.byLocation]: "By location",
  [SHOW_ME_KEYS.byFuneralHome]: "By funeral home"
};

export const DEFAULT_SHOW_ME_VALUE = SHOW_ME_KEYS.datePostedOnline;

export const isUrlShowMeEqualToDefault = (urlShowMeValue: string) => {
  return decodeURIComponent(urlShowMeValue) === DEFAULT_SHOW_ME_VALUE;
};

export const isUrlShowMeEqualToByLocation = (urlShowMeValue: string) => {
  return decodeURIComponent(urlShowMeValue) === SHOW_ME_KEYS.byLocation;
};

interface Node {
  city?: string;
  funeral_home?: string;
}

export const generateAlphabetStepperActiveCharacters = (
  data?: Node[],
  showMeValue?: string
) => {
  if (!data || !showMeValue) {
    return null;
  }


  return (
    data.reduce((acc, el) => {
      const key = isUrlShowMeEqualToByLocation(showMeValue) ? "city" : "funeral_home";
      const title = el[key]
      if (!title) {
        return acc;
      }

      const firstChar = title.slice(0, 1).toUpperCase();
      if (acc.includes(firstChar)) {
        return acc;
      }

      return [...acc, firstChar];
    }, [] as string[])
  );
};
