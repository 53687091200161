import { FormSectionTitle } from "@/components/atoms";
import theme from "@/components/contexts/ThemeProvider/theme";
import {
  EditImageDrawer,
  UploadedMediaPreview
} from "@/components/organisms";
import { ITagFragment } from "@/graphql/fragments/__generated__/tag.types";
import { IUserCreatedObituaryFragment } from "@/graphql/fragments/__generated__/userCreatedObituary.types";
import {
  ChipAutocomplete, DatePicker,
  InfoIcon,
  Input,
  MapboxAutocomplete,
  MapboxType,
  Tooltip
} from "@gannettdigital/community-hub-components";
import { yupResolver } from "@hookform/resolvers/yup";
import get from "lodash/get";
import getConfig from "next/config";
import React, { useMemo, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm
} from "react-hook-form";
import {
  DEFAULT_IMAGE_FRAGMENT,
  FORM_DEFAULT_VALUES,
  validationSchema
} from "./Obituary.form";
import { Divider, FlexInputContainer } from "./ObituaryForm.styled";

const { publicRuntimeConfig } = getConfig();

const FORM_ID = "obituary-form";
const MAX_NUMBER_OF_IMAGES = 2;

interface IProps {
  onSubmit: SubmitHandler<IUserCreatedObituaryFragment>;
  printPublications: { tag: ITagFragment }[];
  defaultValues?: IUserCreatedObituaryFragment;
  formId?: string;
}

const ObituaryForm: React.FC<IProps> = ({
  onSubmit,
  printPublications,
  defaultValues: outerDefaultValues = FORM_DEFAULT_VALUES,
  formId = FORM_ID
}) => {
  // images should default to defaultValues.images.map something
  const [editImageDrawerOpen, setEditImageDrawerOpen] = useState(false);
  const [imageToEdit, setImageToEdit] = useState<any>();

  const printPublicationKVPairs = useMemo(
    () =>
      printPublications.map(pp => ({ key: pp.tag.id, id: pp.tag.id, value: pp.tag.value })),
    [printPublications]
  );

  const defaultValues = {
    ...outerDefaultValues,
    external_links: outerDefaultValues.external_links.length
      ? outerDefaultValues.external_links
      : FORM_DEFAULT_VALUES.external_links,
    obituary_tags: outerDefaultValues.obituary_tags.length
      ? outerDefaultValues.obituary_tags
      : FORM_DEFAULT_VALUES.obituary_tags
  };

  const hookFormMethods = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(validationSchema)
  });
  const { handleSubmit, setValue, formState: {errors}, control } = hookFormMethods;

  const {
    fields: images,
    append: appendImage,
    remove: removeImage
  } = useFieldArray({
    control,
    name: "images"
  });

  const mapboxOptions = {
    endpoint: publicRuntimeConfig.mapboxEndpoint,
    accessToken: publicRuntimeConfig.mapboxAccessToken
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <FormSectionTitle>Your Loved One</FormSectionTitle>
      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.life_stories[0].first_name}
          name={`life_stories.0.first_name`}
          render={props => (
            <Input
              {...props}
              fullWidth
              label="First name"
              error={!!get(errors, "life_stories[0].first_name", false)}
              helperText={get(errors, "life_stories[0].first_name.message")}
              required
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={defaultValues.life_stories[0].middle_name}
          name={`life_stories.0.middle_name`}
          render={props => (
            <Input
              {...props}
              fullWidth
              label="Middle name"
              error={!!get(errors, "life_stories[0].middle_name", false)}
              helperText={get(errors, "life_stories[0].middle_name.message")}
            />
          )}
        />
      </FlexInputContainer>

      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.life_stories[0].last_name}
          name={`life_stories.0.last_name`}
          render={props => (
            <Input
              {...props}
              fullWidth
              label="Last name"
              error={!!get(errors, "life_stories[0].last_name", false)}
              helperText={get(errors, "life_stories[0].last_name.message")}
              required
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={defaultValues.life_stories[0].suffix}
          name={`life_stories.0.suffix`}
          render={props => (
            <Input
              {...props}
              fullWidth
              label="Suffix"
              error={!!get(errors, "life_stories[0].suffix", false)}
              helperText={get(errors, "life_stories[0].suffix.message")}
            />
          )}
        />
      </FlexInputContainer>

      <FormSectionTitle>Date of birth</FormSectionTitle>
      <FlexInputContainer>
        <Controller
          control={control}
          name="life_stories.0.birth"
          render={({field}) => (
            <DatePicker
              {...field}
              disableFuture={true}
              required
              label="Date"
              textFieldProps={{
                fontSize: 14,
                error: !!get(errors, "life_stories[0].birth", false),
                helperText: `${get(errors, "life_stories[0].birth.message")}`
              }}
            />
          )}
        />
      </FlexInputContainer>

      <FormSectionTitle>Date of passing</FormSectionTitle>
      <FlexInputContainer>
        <Controller
          control={control}
          name="life_stories.0.passing"
          render={({field}) => (
            <DatePicker
              {...field}
              disableFuture={true}
              required
              label="Date"
              textFieldProps={{
                fontSize: 14,
                error: !!get(errors, "life_stories[0].passing", false),
                helperText: `${get(errors, "life_stories[0].passing.message")}`
              }}
            />
          )}
        />
      </FlexInputContainer>

      <FormSectionTitle>Hometown</FormSectionTitle>
      <FlexInputContainer>
        <Controller
          defaultValue={defaultValues.life_story_locations[0].city}
          name="life_story_locations.0.city"
          control={control}
          render={props => (
            <MapboxAutocomplete
              autoCompleteProps={props}
              disableClearable
              inputProps={{
                label: "City",
                error: !!get(errors, "life_story_locations[0].city", false),
                helperText: get(errors, "life_story_locations[0].city.message")
              }}
              mapboxOptions={{
                ...mapboxOptions,
                type: MapboxType.place
              }}
              onChangeCallback={data => {
                setValue(`life_story_locations.0.state`, data.state);
                setValue(`life_story_locations.0.country`, data.country);
              }}
            />
          )}
        />

        <Controller
          defaultValue={defaultValues.life_story_locations[0].state}
          name={`life_story_locations.0.state`}
          control={control}
          render={props => (
            <MapboxAutocomplete
              autoCompleteProps={props}
              disableClearable
              inputProps={{
                label: "State",
                error: !!get(errors, "life_story_locations[0].state", false),
                helperText: get(errors, "life_story_locations[0].state.message")
              }}
              mapboxOptions={{
                ...mapboxOptions,
                type: MapboxType.region
              }}
              onChangeCallback={data => {
                setValue(`life_story_locations.0.country`, data.country);
              }}
            />
          )}
        />
      </FlexInputContainer>

      <FlexInputContainer>
        <Controller
          defaultValue={defaultValues.life_story_locations[0].country}
          name={`life_story_locations.0.country`}
          control={control}
          render={props => (
            <MapboxAutocomplete
              autoCompleteProps={props}
              disableClearable
              inputProps={{
                label: "Country",
                error: !!get(errors, "life_story_locations[0].country", false),
                helperText: get(
                  errors,
                  "life_story_locations[0].country.message"
                )
              }}
              mapboxOptions={{
                ...mapboxOptions,
                type: MapboxType.country
              }}
            />
          )}
        />
      </FlexInputContainer>

      <FormSectionTitle>Obituary</FormSectionTitle>
      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.life_stories[0].story}
          name={`life_stories.0.story`}
          render={props => (
            <Input
              {...props}
              fullWidth
              label="Obituary"
              multiline
              minRows={15}
              maxRows={25}
              error={!!get(errors, "life_stories[0].story", false)}
              helperText={get(errors, "life_stories[0].story.message")}
              required
            />
          )}
        />
      </FlexInputContainer>

      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={defaultValues.external_links[0].uri}
          name={`external_links.0.uri`}
          render={props => (
            <Input
              {...props}
              fullWidth
              label="Campaign link"
              error={!!get(errors, "external_links[0].uri", false)}
              helperText={get(errors, "external_links[0].uri.message")}
            />
          )}
        />
      </FlexInputContainer>

      <FormSectionTitle>Photos</FormSectionTitle>
      <UploadedMediaPreview
        images={images}
        onImageEdit={img => {
          setImageToEdit(img);
          setEditImageDrawerOpen(true);
        }}
        onImageDelete={image => {
          const index = images.findIndex(i => i.uri === image.uri);
          if (index >= 0) {
            removeImage(index);
          }
        }}
        onImagesAdded={files => {
          files.forEach(f => {
            appendImage({
              ...DEFAULT_IMAGE_FRAGMENT,
              uri: URL.createObjectURL(f)
            });
          });
        }}
        showDropzone={images.length < MAX_NUMBER_OF_IMAGES}
      />
      <div>
        <EditImageDrawer
          open={editImageDrawerOpen}
          images={images}
          selectedPhoto={imageToEdit}
          onEditClick={image => {
            setImageToEdit(image);
          }}
          onClose={() => {
            setEditImageDrawerOpen(false);
            setImageToEdit(null);
          }}
          onCrop={cropParams => {
            const index = images.findIndex(i => i.uri === imageToEdit.uri);
            if (index < 0) return;

            let params: string;
            try {
              params = JSON.stringify(cropParams);
            } catch (error) {
              console.error("could not stringify crop params");
              return;
            }

            setValue(`images.${index}.params`, params);
          }}
          onDeleteClick={image => {
            const index = images.findIndex(i => i.uri === image.uri);
            if (index < 0) return;

            // TODO: on edit should send request to actually remove
            removeImage(index);
          }}
        />
      </div>

      <Divider variant="middle" />

      <FormSectionTitle>Newspaper publication</FormSectionTitle>
      <FlexInputContainer>
        <Controller
          control={control}
          defaultValue={outerDefaultValues.obituary_tags}
          name="obituary_tags"
          render={({ field: {onChange, value} }) => (
            <ChipAutocomplete
              items={printPublicationKVPairs}
              label="Choose newspaper(s)"
              value={value}
              getOptionLabel={o => o.value}
              onChange={data => {
                onChange(data.map(d => ({ id: d.key,
                  value: d.value})));
              }}
            />
          )}
        />
      </FlexInputContainer>

      <FormSectionTitle>
        Requested print dates{" "}
        <Tooltip
          title="An obituary representative will be in touch to finalize the available print dates."
          placement="left"
          enterTouchDelay={0}
        >
          <span>
            <InfoIcon htmlColor={theme.palette.all.gray2} fontSize="small" />
          </span>
        </Tooltip>
      </FormSectionTitle>
      <FlexInputContainer>
        <Controller
          control={control}
          name="from_sales_orders.0.print_start"
          render={({field}) => (
            <DatePicker
              {...field}
              required
              label="Print start"
              textFieldProps={{
                fontSize: 14,
                error: !!get(errors, "life_stories[0].print_start", false),
                helperText: get(errors, "life_stories[0].print_start.message")
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="from_sales_orders.0.print_end"
          render={({field}) => (
            <DatePicker
              {...field}
              required
              label="Print end"
              textFieldProps={{
                fontSize: 14,
                error: !!get(errors, "life_stories[0].print_end", false),
                helperText: get(errors, "life_stories[0].print_end.message")
              }}
            />
          )}
        />
      </FlexInputContainer>
    </form>
  );
};

export default ObituaryForm;
