import { IManageListObituaryFragment } from "@/graphql/fragments/__generated__/manageListObituary.types";
import type { IObitMemoryCheckFragment } from "@/graphql/fragments/__generated__/obitMemoryCheck.types";
import { useCheckObitsHaveMemoriesQuery } from "@/graphql/queries/__generated__/checkObitsHaveMemories.types";
import { useGetManageListForCurrentUserQuery } from "@/graphql/queries/__generated__/getManageListForCurrentUser.types";
import { GRAPHQL_SERVICES } from "@/services/apolloClient";
import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import useAuth from "@/hooks/useAuth";

interface UseManageObituariesListResult {
  loading: boolean;
  memoriesArray: IObitMemoryCheckFragment[];
  obitsArray: IManageListObituaryFragment[];
  error?: ApolloError;
}

export function useManageObituariesList(): UseManageObituariesListResult {
  const { authState } = useAuth();

  const isAuthenticated = authState?.isAuthenticatedInCurrentContext;
  /************************************************
   * First request to obits service
   ************************************************/

  const {
    data: obitsData,
    error: obitsError,
    loading: obitsLoading,
  } = useGetManageListForCurrentUserQuery({
    ssr: false,
    skip: !isAuthenticated,
  });

  const obitsArray = useMemo(() => obitsData?.obituary || [], [obitsData]);

  /************************************************
   * Once obits-service returns data, find
   * any memories for the resulting obits.
   ************************************************/

  const {
    data: memoriesData,
    error: memoriesError,
    loading: memoriesLoading,
  } = useCheckObitsHaveMemoriesQuery({
    context: { serviceName: GRAPHQL_SERVICES.OBITS_MEMORIES },
    variables: { obit_slugs: obitsArray.map((o) => o.slug) },
    skip: obitsArray.length === 0 || !isAuthenticated,
    ssr: false,
  });

  const memoriesArray = useMemo(
    () => memoriesData?.memories || [],
    [memoriesData]
  );

  /************************************************
   * Combine and transform all obits-service
   * and memories-service data
   ************************************************/

  const error = obitsError || memoriesError;
  const loading = obitsLoading || memoriesLoading || !isAuthenticated;

  return {
    error,
    loading,
    memoriesArray,
    obitsArray,
  };
}
