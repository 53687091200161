import Dropdown from "@/components/atoms/Dropdown/Dropdown";
import styled from "@/components/contexts/ThemeProvider/styled";
import { Colors } from "@/components/contexts/ThemeProvider/theme";
import {
  DEFAULT_SHOW_ME_VALUE,
  SHOW_ME_KEYS,
  SHOW_ME_VALUES
} from "@/helpers/showMe";
import { setStorage } from "@/helpers/storage";
import { unifySansBoldFontFamily } from "@gannettdigital/community-hub-components";
import { SelectChangeEvent } from "@mui/material";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";

const Container = styled("div")({
  "& > .showMeDropdown": {
    background: "#F2F2F2",
    maxHeight: 36,
    width: 203
  },
  "& .select": {
    fontFamily: unifySansBoldFontFamily,
    fontSize: 14,
    color: Colors.gray2,
    maxHeight: 36
  },
  "& .MuiOutlinedInput-root": {
    height: 36
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 2
  }
});

interface IProps {
  onChange: (e: SelectChangeEvent<any>, name: any) => void;
}

const SHOW_ME_OPTIONS = Object.keys(SHOW_ME_KEYS).map(key => ({
  value: key,
  label: SHOW_ME_VALUES[key as SHOW_ME_KEYS]
}));

const ShowMeDropdown: React.FC<IProps> = ({ onChange }) => {
  const { query } = useRouter();

  const advancedFilter =
    query.dateRange ||
    query.city ||
    query.state ||
    query.funeralHome ||
    query.search;

  const showMeKey = useMemo(
    () =>
      query?.showMe && SHOW_ME_VALUES[query?.showMe as SHOW_ME_KEYS]
        ? (query?.showMe as string)
        : DEFAULT_SHOW_ME_VALUE,
    [query?.showMe]
  );

  useEffect(() => {
    if (!advancedFilter) setStorage("showMe", showMeKey);
    else setStorage("showMe", "");
  }, [advancedFilter, showMeKey]);

  return (
    <Container>
      <Dropdown
        options={SHOW_ME_OPTIONS}
        additionalStyles={{ formControlStyles: "showMeDropdown" }}
        value={showMeKey}
        onChangeCallback={onChange}
      />
    </Container>
  );
};

export default ShowMeDropdown;
